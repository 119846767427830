import { Injectable } from '@angular/core';

import { TraceService } from '../../trace/trace.service';
import { isNullOrUndefined } from '../../utility/common.utility';
import { SessionCookieStorage } from '../cookies-sessionstorage/cookies-sessionstorage.model';
import { TraceModules } from '../trace-modules';

/**
 * This service provides user and culture specific information.
 *
 * @export
 * @class AppContextService
 */
@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private _pathName!: string;

  private _domainName!: string;

  public constructor(private readonly traceService: TraceService) {
    this.traceService.info(TraceModules.utilities, 'LocationService created.');
  }

  /**
   * Gets the path name of the location URL.
   *
   * @readonly
   * @type {string}
   * @memberof LocationService
   */
  public get pathName(): string {
    this._pathName = sessionStorage.getItem(SessionCookieStorage.PathName)!;
    return this._pathName;
  }

  /**
   * Set the location Path Name
   *
   * @returns
   * @memberof AppContextService
   */
  public setPathName(pathName: string): void {
    if (!isNullOrUndefined(pathName)) {
      this._pathName = pathName;
      sessionStorage.setItem(SessionCookieStorage.PathName, pathName);
    } else {
      this.traceService.error(TraceModules.utilities, 'invalid parameter for pathName');
    }
  }

  /**
   * Gets the Domain name of the location URL.
   *
   * @readonly
   * @type {string}
   * @memberof LocationService
   */
  public get domainName(): string {
    this._domainName = sessionStorage.getItem(SessionCookieStorage.DomainName)!;
    return this._domainName;
  }

  /**
   * Set the location Domain Name
   *
   * @returns
   * @memberof AppContextService
   */
  public setDomainName(domainName: string): void {
    if (!isNullOrUndefined(domainName)) {
      this._domainName = domainName;
      sessionStorage.setItem(SessionCookieStorage.DomainName, domainName);
    } else {
      this.traceService.error(TraceModules.utilities, 'invalid parameter for domainName');
    }
  }

}
