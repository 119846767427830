<div
  tabindex="0"
  [attr.role]="
    (!showLoadingIndicator && value && value.length > 0) || showLoadingIndicator ? 'list' : null
  "
>
  @if (!showLoadingIndicator && value) {
    @if (value.length > 0) {
      @for (item of value; track $index) {
        <si-timeline-widget-item [value]="item" />
      }
    } @else {
      <ng-content select="[empty-state]" />
    }
  } @else {
    @for (some of ghosts; track $index) {
      <si-timeline-widget-item [value]="undefined" [showDescription]="showDescription" />
    }
  }
</div>
