import { Location, PlatformLocation } from '@angular/common';
import { Component, HostBinding, Inject, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FullQParamId, IHfwMessage, ISnapInConfig, MessageParameters, MobileNavigationService, QParam, SnapInBase } from '@gms-flex/core';
import { BrowserObject, CnsHelperService, CnsLabel, CnsLabelEn, GmsMessageData, MultiMonitorServiceBase, ObjectMessageType } from '@gms-flex/services';
import { AppContextService, isNullOrUndefined, SettingsServiceBase, TraceService } from '@gms-flex/services-common';
import { AggregateViewId, ObjectManagerComponent, ObjectManagerConfig, SelectedItemsChangedArgs, SelectionMenuItem, SelectionPriority,
  SelectionRequest, TreeStyle } from '@gms-flex/snapin-common';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subject, Subscription } from 'rxjs';

import { SystemBrowserSettingsServiceBase } from '../services';
import { SystemBrowserSnapInService } from '../services/system-browser-snapin.service';
import { TraceModules } from '../shared/trace-modules';
import { SystemBrowserViewModelBase } from '../view-model/snapin-vm.base';

export interface ParamsSendMessage {
  messageBody: any;
  preselection: boolean;
  qParam?: QParam;
  broadcast: false;
  applyRuleId?: string;
  secondarySelectionInSinglePane?: boolean;
}

export enum SelectionModeEn {
  Primary,
  Secondary,
  Manual
}

@Component({
  selector: 'gms-system-browser-snapin',
  templateUrl: './system-browser-snapin.component.html',
  styleUrl: '../gms-system-browser-snapin.scss',
  providers: []
})

export class SystemBrowserSnapInComponent extends SnapInBase implements OnInit, OnDestroy {

  @HostBinding('class.hfw-flex-container-column') public guardFrame = true;
  @HostBinding('class.hfw-flex-item-grow') public guardGrow = true;
  @HostBinding('class.snapin-container') public guardSnapIn = true;
  @HostBinding('class.elevation-1') public guardElevation = true;

  public enableDirectedSelection = true;
  public isMobileView = false;
  public sysBrowActive = false;
  public backNavigationInProgress = false;
  public hasWindows = false;

  @ViewChild(ObjectManagerComponent, { static: false })
  public omComponent: ObjectManagerComponent;

  public treeStyle: TreeStyle = TreeStyle.Responsive;
  public vm: SystemBrowserViewModelBase;
  public showDefaultPropertyValue = false;
  public selectionMenuItems: SelectionMenuItem[] = [];
  public postActiveView: boolean;

  public updateSelectionInd: ReplaySubject<SelectionRequest>;
  public reattachInd: Subject<void>;

  public showFilter = true;

  private selectionMode: SelectionModeEn = SelectionModeEn.Primary;
  private readonly activeCnsLabel: CnsLabel;
  private readonly searchNodesSubscription: Subscription = null;
  private multimonitorSubscription: Subscription = null;
  private readonly subscriptions: Subscription[] = [];
  private readonly languageCode: string = 'en-US';
  private lastFilterResultsSelection: BrowserObject[] = [];
  private lastSentSelection: BrowserObject[] = [];
  private readonly _trModule: string = TraceModules.sysBrowser;
  private hldlConfig: any = null;
  private readonly _settingId: string = 'Web_SystemBrowser_SelectedHierarchy';
  private _initialViewSettings = '';
  private fullQParamId: FullQParamId;
  private readonly sendTowindowText: string;
  private readonly primarySelectionItem: SelectionMenuItem = { description: 'Send to primary', id: 'Primary' };
  private readonly secondarySelectionItem: SelectionMenuItem = { description: 'Send to secondary', id: 'Secondary' };
  private readonly sendToWindowItem: SelectionMenuItem = { description: 'Send to other window', id: 'SendToWindow' };
  private readonly copyNameItem: SelectionMenuItem = { description: 'Name', id: 'CopyName' };
  private readonly copyDescriptionItem: SelectionMenuItem = { description: 'Description', id: 'CopyDescription' };
  private readonly copyAliasItem: SelectionMenuItem = { description: 'Alias', id: 'CopyAlias' };
  private readonly copyDesignationItem: SelectionMenuItem = { description: 'Designation', id: 'CopyDesignation' };
  private readonly copyItem: SelectionMenuItem = { description: 'Copy', id: 'Copy', 
    items: [this.copyDescriptionItem, this.copyNameItem, this.copyAliasItem, this.copyDesignationItem] };
  private readonly separatorItem: SelectionMenuItem = { description: '-', id: 'Separator' };

  public get isSelectionByName(): boolean {
    if (isNullOrUndefined(this.activeCnsLabel)) {
      return false;
    }
    return this.activeCnsLabel.cnsLabel === CnsLabelEn.Name ||
      this.activeCnsLabel.cnsLabel === CnsLabelEn.NameAndDescription ||
      this.activeCnsLabel.cnsLabel === CnsLabelEn.NameAndAlias;
  }

  public get omClientId(): string {
    return this.fullId ? this.fullId.fullId() : undefined;
  }

  public get omConfig(): ObjectManagerConfig {
    return undefined;
  }

  public constructor(
    private readonly traceService: TraceService,
    messageBroker: IHfwMessage,
    activatedRoute: ActivatedRoute,
    private readonly ngZone: NgZone,
    private readonly systemBrowserSnapinService: SystemBrowserSnapInService,
    private readonly cnsHelperService: CnsHelperService,
    private readonly translateService: TranslateService,
    private readonly appContextService: AppContextService,
    private readonly snapinConfig: ISnapInConfig,
    private readonly settingsService: SettingsServiceBase,
    private readonly multiMonitorService: MultiMonitorServiceBase,
    private readonly browserLocation: Location,
    private readonly platformLocation: PlatformLocation,
    private readonly systemBrowserSettingsService: SystemBrowserSettingsServiceBase,
    @Inject(MobileNavigationService) private readonly mobileNavigationService: MobileNavigationService) {

    super(messageBroker, activatedRoute);

    // Subject used to deliver selection requests to the Object Manager component.
    // These are 'internal' requests that are initiated through URL query parameter changes and
    // received HFW select messages.  During startup, it is possible that an indication is
    // generated on this subject before the Object Manager component has been fully activated
    // and listening for such indications.  To ensure no 'early' indications are missed,
    // a ReplaySubject is used to buffer the last few indications created.  These will be
    // waiting and delivered to the Object Manager in the case it subscribes 'late' to the Subject.
    // Buffering just the last few indications should be plenty to ensure nothing is missed;
    // at present these can be generated only through the two channels mentioned above.
    this.updateSelectionInd = new ReplaySubject<SelectionRequest>(3);
    this.reattachInd = new Subject<void>();
    this.activeCnsLabel = this.cnsHelperService.activeCnsLabelValue;

    // Initialize injected Translate Service with correct language settings
    this.appContextService.defaultCulture
      .subscribe(defaultLocale => this.translateService.setDefaultLang(defaultLocale || this.translateService.getBrowserLang() || 'en-US'));
    this.appContextService.userCulture
      .subscribe(userLocale => this.translateService.use(userLocale || this.translateService.defaultLang).subscribe());

    // Set tree selection menu from i18n string resources
    // NOTE: The si-tree-view control (used by the object-manager) treats the menu command
    //  items effectively as a one-time binding (evaluated only at time of control initialization).
    //  For this reason, we MUST initialize now the number of menu items and item ids (cannot wait
    //  for language resources also to be fetched).  The menu text will be updated when
    //  received from the TranslationService.  See Defect-1060285.

  }

  public updateHasWindows(hasWindows: boolean): void {
    this.hasWindows = hasWindows;
    this.setMenuItems();
  }

  public ngOnInit(): void {
    this.showFilter = this.systemBrowserSettingsService.showFilterButton();

    this.vm = this.systemBrowserSnapinService.registerViewModel(this.fullId);
    this.vm.activate().subscribe();

    this.getHldlConfigs();
    // get user's saved last view
    // this.getSelectedHierarchyData();

    // Subscribe for qParam changes.
    this.fullQParamId = new FullQParamId(this.fullId.frameId, 'SystemQParamService', 'primary');
    this.subscriptions.push(this.messageBroker.getQueryParam(this.fullQParamId).subscribe(qParam => {
      this.onQueryParamChange(qParam);
    }));

    // Subscribe for incoming HFW messages
    this.subscriptions.push(this.messageBroker.getMessage(this.fullId).subscribe(mesg => {
      this.onHfwMessage(mesg);
    }));

    // Subscribe to the screen size change event to configure mobile view
    this.subscriptions.push(this.mobileNavigationService.mobileOnlyVisibility$.subscribe((isVisible: boolean) => {
      this.isMobileView = isVisible;
      this.setTreeStyle();
    }));

    // Subscribe If system-browser is active
    this.subscriptions.push(this.mobileNavigationService.sysBrowActive$.subscribe((sysBrowActive: boolean) => {
      this.sysBrowActive = sysBrowActive;
    }));

    // Subscribe If back navigation is in progress
    this.subscriptions.push(this.mobileNavigationService.backNavigate$.subscribe((backNavigate: boolean) => {
      this.backNavigationInProgress = backNavigate;
      this.navigateBack();
    }));

    this.initMenuItems();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => { if (subscription != null) { subscription.unsubscribe(); } });
    this.messageBroker.clearLastMessage(this.fullId);

    if (this.searchNodesSubscription != null) {
      this.searchNodesSubscription.unsubscribe();
    }

    if (this.multimonitorSubscription != null) {
      this.multimonitorSubscription.unsubscribe();
    }
  }

  public onQueryParamChange(qDesignation: string): void {
    if (qDesignation != null) {
      // SPECIAL CASE: When a selection is sent by System Browser via an HFW message, the query-param
      //  in the URL is updated by the HFW.  This change then echoes back, which triggers a change
      //  indication here.  This check is meant to ignore these change indications, which are nothing
      //  more than a notification of a selection initiated by System Browser itself, and avoid sending
      //  a duplicate HFW selection message.

      // If System Browser is active and node is changed, notify mobile view service to close the system browser.
      if (this.sysBrowActive) {
        this.sysBrowActive = false;
        this.mobileNavigationService.setSysBrowActive(this.sysBrowActive);
      }

      if (this.lastSentSelection?.some(bo => bo.Designation === qDesignation)) {
        return; // ignore indication as a result of the previous selection!
      }
      this.lastSentSelection = [];
      // NOTE: The HFW is listening to query-param changes associated with this SNI (configured through
      //  the HLDL).  When these occur, the HFW will send a selection message on the SNI's behalf--that is,
      //  there is no need for the SNI to send a selection message; we only need to update the selected
      //  items in the view to match the selection sent by the HFW.  For this reason, we set the
      //  `supressNotification` flag to TRUE in the request.
      this.traceService.debug(this._trModule, 'Query parameter change: designation=%s', qDesignation);
      this.updateSelectionInd.next({
        selection: qDesignation,
        priority: SelectionPriority.QueryParam,
        sendMessage: false
      });
    }
  }

  public onHfwMessage(mesg: any): void {
    const hfwMesg: GmsMessageData = mesg as GmsMessageData;
    if (!hfwMesg) {
      return;
    }
    const hfwDesignation: string = hfwMesg.data && hfwMesg.data.length > 0 ? hfwMesg.data[0].Designation : undefined;
    this.traceService.debug(this._trModule, 'Received HFW message: mesgType=%s, designation=%s', hfwMesg.selectionType, hfwDesignation);
    if (hfwDesignation) {
      this.updateSelectionInd.next({
        selection: hfwMesg.data[0].Designation,
        priority: SelectionPriority.HfwMessage,
        sendMessage: true,
        customData: hfwMesg.customData
      });
    }
  }

  public onSelectionModePrimary(): void {
    this.selectionMode = SelectionModeEn.Primary;
  }

  public onSelectionModeManual(): void {
    this.selectionMode = SelectionModeEn.Manual;
  }

  public onSelectionModeSecondary(): void {
    this.selectionMode = SelectionModeEn.Secondary;
  }

  public onViewChanged(event: any): void {
    this.setSelectedHierarchyData(event as AggregateViewId);
  }

  public onBeforeAttach(): void {
    super.onBeforeAttach();
    this.reattachInd.next(undefined);
  }

  public navigateBack(): void {
    if (this.backNavigationInProgress) {
      this.browserLocation.back();
    }
    this.backNavigationInProgress = false;
  }

  public onBrowserSelectionChanged(sel: SelectedItemsChangedArgs): void {
    if (!sel || !sel.objects || sel.objects.length === 0) {
      return;
    }
    if (!sel.sendMessage) {
      return;
    }
    this.traceService.debug(this._trModule, 'Received selected items changed ind: count=%s, menuId=%s, items=%s %s',
      sel.objects.length,
      sel.menuId,
      sel.objects.slice(0, 5).map(obj => obj.Designation).join(', '),
      sel.objects.length > 5 ? '...' : '');
    if (sel.menuId === 'newTab') {
      const basUrl: string = (this.platformLocation as any)._location.href;
      window.open(basUrl, '_blank');
    } else {
      if (this.sysBrowActive) {
        this.sysBrowActive = false;
        this.mobileNavigationService.setSysBrowActive(this.sysBrowActive);
      }
      this.sendSelectionMessage(sel.objects, true, sel.menuId === 'Secondary', sel.menuId, sel.customData);
    }
  }

  public onFilterResultsSelectionChanged(nodes: BrowserObject[]): void {
    if (isNullOrUndefined(nodes) || nodes.length === 0) {
      return;
    }
    this.lastFilterResultsSelection = nodes;
    if (this.selectionMode !== SelectionModeEn.Manual) {
      this.sendSelectionMessage(nodes, true, this.selectionMode === SelectionModeEn.Secondary);
    }
  }

  private initMenuItems(): void {
    this.translateService.get([
      'SELECTION-SEND-TO-PRIMARY', 
      'SELECTION-SEND-TO-SECONDARY', 
      'SELECTION-SEND-TO-WINDOW',
      'SELECTION-COPY',
      'SELECTION-COPY-DESCRIPTION',
      'SELECTION-COPY-NAME',
      'SELECTION-COPY-ALIAS',
      'SELECTION-COPY-DESIGNATION'
    ]).subscribe({
      next: res => {
        this.primarySelectionItem.description = res['SELECTION-SEND-TO-PRIMARY'];
        this.secondarySelectionItem.description = res['SELECTION-SEND-TO-SECONDARY'];
        this.sendToWindowItem.description = res['SELECTION-SEND-TO-WINDOW'];
        this.copyItem.description = res['SELECTION-COPY'];
        this.copyDescriptionItem.description = res['SELECTION-COPY-DESCRIPTION'];
        this.copyAliasItem.description = res['SELECTION-COPY-ALIAS'];
        this.copyDesignationItem.description = res['SELECTION-COPY-DESIGNATION'];
        this.copyNameItem.description = res['SELECTION-COPY-NAME'];

        this.multiMonitorService.isSingleSystemManager().then(hasWindows => {
          this.updateHasWindows(hasWindows);
        });

        // Send to window
        // Only for multimonitor
        if (this.multiMonitorService.runsInElectron) {
          // Set "send to window" menu item
          // this.setSendToWindowMenuItem(this.sendTowindowText);
          // Update menu based on number of managers
          this.subscribeMultimonitorConfigChange();
        }
      }
    });
  }

  private getHldlConfigs(): void {
    this.hldlConfig = this.snapinConfig.getSnapInHldlConfig(this.fullId, this.location);

    if (isNullOrUndefined(this.hldlConfig)) {
      this.treeStyle = TreeStyle.Responsive;
    }

    this.subscriptions.push(this.snapinConfig.paneCanBeDisplayed(this.fullId.frameId, 'comparison-pane').subscribe((paneCanBeDisplayed: boolean) => {
      this.enableDirectedSelection = paneCanBeDisplayed;
      this.setMenuItems();
    }));

    // checks sendTo buttons are needed
    this.subscriptions.push(
      this.messageBroker.getCurrentLayoutId(this.fullId.frameId).subscribe(
        (layoutId: string) => {
          // default values in the event that layoutId does not have corresponding config array
          // this.enableDirectedSelection = true;
          if (this.hldlConfig != null) {
            // should we show the default property value?
            const enaVals: any = this.hldlConfig.enableValues;
            if (enaVals != null && Array.isArray(enaVals)) {
              const item: any = enaVals[0];
              this.setShowDefaultPropertyValue(item);
            }

            // Set tree style
            const treeAttr: any = this.hldlConfig.treeAttributes;
            if (!isNullOrUndefined(treeAttr) && Array.isArray(treeAttr)) {
              const attr: any = treeAttr.find(a => a.layout === layoutId);
              this.setTreeStyle(attr);
            }

            // Set flag indicating if changes to the selected view should be posted to the CnsHelperService
            this.setPostActiveView(this.hldlConfig.postActiveView);
          }
        },
        err => {
          this.traceService.error(this._trModule, 'getHldlConfigs request failed: %s', err);
        })
    );
  }

  private setShowDefaultPropertyValue(a: any): void {
    let flag: boolean = this.showDefaultPropertyValue;
    if (a != null) {
      const b: boolean = a.enabled;
      if (b != null) {
        if (typeof b === 'boolean') {
          flag = b;
        } else if (typeof b === 'string') {
          const s: string = (b as string).toLowerCase();
          if (s === 'true') {
            flag = true;
          } else if (s === 'false') {
            flag = false;
          }
        }
      }
    }
    // we only set this property when there is a change;
    // an update might cause some downstream activity.
    if (this.showDefaultPropertyValue !== flag) {
      this.showDefaultPropertyValue = flag;
    }
  }

  private setPostActiveView(val: any): void {
    let flag = false;
    if (!isNullOrUndefined(val)) {
      if (typeof val === 'boolean') {
        flag = Boolean(val);
      } else if (typeof val === 'string') {
        const sval: string = val.toLowerCase();
        flag = (sval === 'true');
      }
    }
    this.postActiveView = flag;
  }

  private setTreeStyle(attr?: any): void {
    this.treeStyle = TreeStyle.Responsive; // default
    if (this.isMobileView) {
      this.treeStyle = TreeStyle.Flat;
    } else {
      if (!isNullOrUndefined(attr)) {
        // Boolean type attribute value
        if (typeof attr.flat === 'boolean') {
          this.treeStyle = attr.flat ? TreeStyle.Flat : TreeStyle.Expandable;
          // String type attribute value
        } else if (typeof attr.flat === 'string') {
          const flag: string = (attr.flat as string).toLowerCase();
          if (flag === 'true') {
            this.treeStyle = TreeStyle.Flat;
          } else if (flag === 'false') {
            this.treeStyle = TreeStyle.Expandable;
          }
        }
      }
    }
  }

  /**
   * Send a selection message through the HFW.
   */
  private sendSelectionMessage(nodes: BrowserObject[], preselect: boolean, secondaryPane: boolean, selectionId?: string, customData?: any): void {
    if (isNullOrUndefined(nodes) || nodes.length === 0) {
      return;
    }
    try {
      this.lastSentSelection = nodes;
      const messageTypes: string[] = [];
      nodes.forEach((n: BrowserObject) => {
        messageTypes.push(n.Attributes.ManagedTypeName);
      });

      const messageBody: GmsMessageData = new GmsMessageData(nodes);

      // if (this.traceService.isDebugEnabled(this._trModule)) {
      let mesgStr = `messageTypes=${messageTypes}, preselect=${preselect}, secondaryPane=${secondaryPane}, messageBody=`;
      if (messageBody) {
        const elCount: number = messageBody.data ? messageBody.data.length : 0;
        mesgStr += `{selectionType=${messageBody.selectionType}, dataCount=${elCount}`;
        if (elCount === 1) {
          mesgStr += `, data=${messageBody.data[0].Designation}`;
        } else if (elCount > 1) {
          mesgStr += `, dataFirst=${messageBody.data[0].Designation}, dataLast=${messageBody.data[elCount - 1].Designation}`;
        }
        mesgStr += `}`;
      }
      this.traceService.debug(this._trModule, 'Send HFW message: %s', mesgStr);
      // }
      messageBody.customData = customData;
      const qParam: QParam = secondaryPane ? undefined : { name: this.fullQParamId.fullId(), value: nodes[0].Designation };

      const messageToSend: ParamsSendMessage = {
        messageBody,
        preselection: preselect,
        qParam,
        broadcast: false,
        applyRuleId: secondaryPane ? 'SecondarySelection' : undefined
      };

      if (selectionId === 'SendToWindow') {
        // Multimonitor
        // Send selection to other window
        const message: MessageParameters = {
          messageBody,
          qParam,
          types: messageTypes
        };

        const object = {
          type: ObjectMessageType.SendSelectionMessage,
          data: { message }
        };
        this.multiMonitorService.sendObjectToWindow(object);
      } else {
        this.sendMessage(
          messageTypes,
          messageToSend).subscribe((res: boolean) => {
          this.traceService.debug(this._trModule, 'sendMessage() completed. result: %s', res);
          // NOTE: handle canceled node selection.
        });
      }
    } catch (err) {
      this.traceService.error(this._trModule, 'Error on sendMessage: %s', err);
    }
  }

  private setSelectedHierarchyData(selectedView: AggregateViewId): void {
    if (!selectedView) {
      return;
    }
    const enc: string = selectedView.type + '.' + selectedView.description;
    if (this._initialViewSettings === enc) {
      return;
    }
    this._initialViewSettings = enc;
    this.ngZone.runOutsideAngular(() => {
      this.settingsService.putSettings(this._settingId, this._initialViewSettings).subscribe(
        val => this.traceService.info(this._trModule, 'onPutSettings() : %s', val.valueOf.toString()),
        err => this.traceService.error(this._trModule, err)
      );
    });
  }

  /**
   * Receives changes on multimonitor configuration
   */
  private subscribeMultimonitorConfigChange(): void {
    this.multimonitorSubscription = this.multiMonitorService.onCurrentMultiMonitorConfigurationChanged().subscribe(config => {
      const numWindows: number = config?.configuration?.windows?.filter(w => w.manager.managerType !== 'event').length || 0;
      const hasWindows: boolean = numWindows > 1;
      this.updateHasWindows(hasWindows);
    });
  }

  /**
   * Sets "SendToWindow" menu item
   * according to the number of open system managers
   *
   * @param text "Send to window" menu item title
   */
  private setMenuItems(): void {
    if (this.hasWindows) {
      if (this.enableDirectedSelection) {
        this.showAllMenuItems();
      } else {
        this.showSendToWindowItem();
      }
    } else {
      this.showSelectionItems();
    }
  }

  private showSendToWindowItem(): void {
    this.selectionMenuItems = Array.of(this.sendToWindowItem, this.separatorItem, this.copyItem);
  }

  private showSelectionItems(): void {
    this.selectionMenuItems = Array.of(this.primarySelectionItem, this.secondarySelectionItem, this.separatorItem, this.copyItem);
  }

  private showAllMenuItems(): void {
    this.selectionMenuItems = Array.of(this.primarySelectionItem, this.secondarySelectionItem, this.sendToWindowItem, this.separatorItem, this.copyItem);
  }

/*
  private getSelectedHierarchyData(): void {
    this.settingsService.getSettings(this._settingId).subscribe(
      val => {
        this.onGetSettings(val);
      },
      err => {
        this.traceService.error(this._trModule, err);
      }
    );
  }

  private onGetSettings(settings: string): void {
    this._initialViewSettings = settings;
    if (settings != undefined) {
      const parts: string[] = settings.split(".");
      this._initialViewType = parseInt(parts[0], 10);
      this._initialViewDescription = parts[1];
      this._initialViewSettings = this._initialViewType + "." + this._initialViewDescription;
      this.updateSelectionInd.next({
        selection: {
          type: this._initialViewType,
          description: this._initialViewDescription
        } as AggregateViewId,
        priority: SelectionPriority.Initial
      });
    }
  }
*/
}
