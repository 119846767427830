import { Injectable } from "@angular/core";
import { ObjectManagerSettingsServiceBase } from "@gms-flex/snapin-common";

@Injectable()
export class ObjectManagerSettingsBxSubstituteService extends ObjectManagerSettingsServiceBase {
  public constructor() {
    super();
  }

  public showFilterButton(): boolean {
    return false;
  }
}