import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Base class for the location service.
 * Provides the functionality to get location information's like children
 *
 * @export
 * @abstract
 * @class LocationServiceBase
 */
@Injectable({
  providedIn: 'root'
})
export abstract class LocationServiceBase {

  public abstract getParentLocationId(location: object): string;

  /**
   * Returns the root nodes of a partition. Root nodes have no parent location. An optional filter (location name or Id) can be set.
   * Root nodes are of type Campus of Building.
   */
  public abstract getLocationRoots(partitionId: string, filter?: string, filterType?: unknown): Observable<{ campusRoots: object[]; buildingRoots: object[] }> ;

  /**
   * Reads the location from the backend (or the cache)
   */
  public abstract getLocationById(partitionId: string, locationId: string): Observable<object | undefined>;

  /**
   * Returns the children of the parentLocationId.
   *
   * Parent-Child relationsips are as follows:
   * Parent: CampusBx => Children: CampusPartBx | BuildingBx | OutsideBx
   * Parent: CampusPartBx => Children: BuildingBx | OutsideBx
   * Parent: BuildingBx => Children: BuildingPartBx | FloorBx | OutsideBx
   * Parent: BuildingPartBx => Children: FloorBx | OutsideBx
   * Parent: FloorBx => Children: FloorAreaBx | MultiFloorAreaBx | RoomBx
   * Parent: FloorAreaBx => Children: RoomBx[]
   * Parent: MultiFloorAreaBx => Children: RoomBx[]
   * Parent: RoomBx => Children: RoomSegmentBx[]
   * Parent: RoomSegmentBx => Children: none
   * Parent: OutsideBx => Children: none
   */
  public abstract getLocationChildren(partitionId: string, parentLocationId: string, filter?: string, filterType?: unknown): Observable<object[]> ;

  /**
   * Returns all campuses of the partition. An optional filter (location name or Id) can be set.
   * If name is specified, only the campuses with that name are returned.
   */
  public abstract getLocationCampus(partitionId: string, filter?: string, filterType?: unknown): Observable<object[]>;

  /**
   * Returns all buildings of the partition. An optional filter (location name or Id) can be set.
   * If name is specified, only the buildings with that name are returned.
   */
  public abstract getLocationBuilding(partitionId: string, filter?: string, filterType?: unknown): Observable<object[]>;

  public abstract getLocationHasAssetsIds(partitionId: string, locationId: string, entityType: unknown): Observable<string[]>;

  public abstract getGeometriesInfo(coordinates: object, type: unknown): object[];

  /**
   * it return a boolean observable, the value will be true if the location is a campus, otherwise it will be false
   * @param partitionId the partitionId of the object you want to check
   * @param locationId the objectId of the object you want to check
   */
  public abstract isLocationACampus(partitionId: string, locationId: string): Observable<boolean>;

  /**
   * it return a string observable, with the site name of the object
   * @param partitionId the partitionId of the object you want to check
   * @param locationId the objectId of the object you want to check
   */
  public abstract getSiteName(partitionId: string, locationId: string): Observable<string>;

  /**
   * it return a string array observable, with the ids of the buildings inside the campus
   * @param partitionId the partitionId of the object you want to check
   * @param locationId the objectId of the object you want to check
   */
  public abstract getBuildingsIdsOfACampus(partitionId: string, locationId: string): Observable<string[]>;
}
