import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  LoadingState, Partition, Permission, SiAppDataService, SiCustomerListComponent } from '@building-x/common-ui-ng';
import { IHfwMessage } from '@gms-flex/core';
import { ModeData } from '@gms-flex/services-common';
import { TranslateService } from '@ngx-translate/core';
import { SiToastNotificationService } from '@simpl/element-ng';
import { firstValueFrom, Subscription } from 'rxjs';

import { ContextService } from '../bx-gms-mapper/state/context.service';
import { Customer } from '../bx-services/user/user-self-proxy.model';
import { cbmsAppPermission, CustomerSelectorStateService } from './customer-selector-state.service';

@Component({
  selector: 'gms-customer-selector',
  templateUrl: './customer-selector.component.html'
})

export class CustomerSelectorComponent implements OnInit, OnDestroy {

  public allowFilter = true;
  public showPartitionSubscriptionName = true;
  @ViewChild('customerList') public custList!: SiCustomerListComponent;

  protected appPermission = cbmsAppPermission;

  private toastTitle;
  private toastMessage;
  private translateSubscription: Subscription;
  private messageBrokerSubscription: Subscription;
  constructor(
    private readonly csStateService: CustomerSelectorStateService,
    private readonly contextService: ContextService,
    private readonly siAppDataService: SiAppDataService,
    private readonly toastNotificationService: SiToastNotificationService,
    @Inject(IHfwMessage) private readonly messageBroker: IHfwMessage,
    @Inject(TranslateService) private readonly translateService: TranslateService) {
  }

  public ngOnInit(): void {
    setTimeout(() => {
      // extremly ugly!
      // however, the customer list maintains this variable internally as well. without this I cannot get rid of the bloody spinner!
      this.custList.isAllPartitionsLoaded = true;
      // this.siAppDataService.isAllPartitionsLoaded$.next(LoadingState.Complete);
      this.siAppDataService.isAllCustomersLoaded$.next(LoadingState.Complete);
    }, 100);
    this.translateSubscription = this.translateService
      .get([
        "BASIC_TEXTS.WARNING",
        "MESSAGES.AUTO_CLOSE_INVESTIGATIVE"]).subscribe(values => {
        this.toastTitle = values["BASIC_TEXTS.WARNING"];
        this.toastMessage = values["MESSAGES.AUTO_CLOSE_INVESTIGATIVE"];
      })

    // Reload data if the customer selection changed, but the users exits without applying changes on partitions
    if (this.csStateService.unconfirmedCustomer && this.csStateService.selectedCustomer && 
      this.csStateService.unconfirmedCustomer.id != this.csStateService.selectedCustomer.id) {
      this.csStateService.reloadData(this.csStateService.selectedCustomer.id);
    }
  }

  public ngOnDestroy(): void {
    this.translateSubscription?.unsubscribe();
    this.messageBrokerSubscription?.unsubscribe();
  }

  public get selectedCustomer(): Customer {
    return this.csStateService.selectedCustomer;
  }

  public get selectedPartitions(): Partition[] {
    return this.csStateService.selectedCustomerPartitions;
  }

  public get isAllPartitionsLoaded(): boolean {
    return this.csStateService.isAllPartitionsLoaded;
  }

  public get allCustomers(): Customer[] {
    return this.csStateService.allCustomers;
  }

  public get customerPartitions(): Partition[] {
    return this.csStateService.customerPartitions;
  }

  public get allPermissions(): Permission {
    return this.csStateService.allPermissions;
  }

  public onCustomerChange(event): void {
    this.csStateService.onCustomerChange(event);
    setTimeout(() => {
      // extremly ugly
      this.custList.changedPartitionSelectInput = {};
      this.custList.isAllPartitionsLoaded = true;
      // this.siAppDataService.isAllPartitionsLoaded$.next(LoadingState.Complete);
    }, 100);
  }

  public async onSelectedCustomerPartitions(event): Promise<void> {
    /* refresh when you change customer and apply, to avoid empty system browser issue */
    const isDifferentCustomer = this.selectedCustomer.id !== event.customer?.id;
    /* refresh when you select different partitions and apply, to avoid empty system browser issue
      (no common partition with the initial array) */

    // TODO!!!!! use saved partitions instead of the component partitions? are they different?
    // const selectedData = await firstValueFrom(this.contextService.selectedData$);

    // console.log(`### onSelectedCustomerPartitions (component) - 
    //   this.selectedPartitions = ${JSON.stringify(this.selectedPartitions, null, 2)}.
    //   selectedData.partitions = ${JSON.stringify(selectedData.partitions, null, 2)}`);

    const hasNoCommonElements = !isDifferentCustomer &&
      !this.checkForCommonPartitions(this.selectedPartitions, event.partitions); // TODO: selectedData.partitions?
    
    /* refresh when you add a different partition and apply, to avoid indentation issue
      (common partition and at least one different with the initial array) */
    const newPartitionAdded = !isDifferentCustomer &&
      this.newPartitionAdded(this.selectedPartitions, event.partitions); // TODO: selectedData.partitions?
    
    const shouldRefresh = isDifferentCustomer || hasNoCommonElements || newPartitionAdded;
    
    this.csStateService.onSelectedCustomerPartitions(event);
    this.messageBroker.getCurrentMode().subscribe(mode => this.handleInvestigativeTreatment(mode, shouldRefresh)).unsubscribe();
  }

  private handleInvestigativeTreatment(mode: ModeData, shouldRefresh: boolean): void {
    if (mode.id === 'investigative') {
      this.messageBrokerSubscription = this.messageBroker.changeMode({ id: 'default', relatedValue: null }, undefined).subscribe(() => {
        if (shouldRefresh) {
          window.location.reload();
        }
      });
      this.toastNotificationService.queueToastNotification('warning', this.toastTitle, this.toastMessage);
    } else if (shouldRefresh) {
      window.location.reload();
    }
  }

  private checkForCommonPartitions(initial: Partition[], updated: Partition[]): boolean {
    const initialIds = new Set(initial.map(partition => partition.id));
    return updated.some(partition => initialIds.has(partition.id));
  }

  private checkForUncommonPartitions(initial: Partition[], updated: Partition[]): boolean {
    const initialIds = new Set(initial.map(partition => partition.id));
    for (const item of updated) {
      if (!initialIds.has(item.id)) {
        return true;
      }
    }
    return false;
  }

  private newPartitionAdded(initial: Partition[], updated: Partition[]): boolean {
    const newPartitionAdded = this.checkForCommonPartitions(initial, updated) && this.checkForUncommonPartitions(initial, updated);
    return newPartitionAdded;
  }
}
