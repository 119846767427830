/**
 * Base class for the object-manager-settings service.
 * Provides the methods to get if object manager functionalities are visible.
 *
 * @export
 * @abstract
 * @class ObjectManagerSettingsServiceBase
 */

export abstract class ObjectManagerSettingsServiceBase {
  public abstract showFilterButton(): boolean;
}