import { Injectable } from "@angular/core";
import { FullQParamId, IHfwMessage, MessageParameters, QParam } from "@gms-flex/core";
import { GmsMessageData } from "@gms-flex/services";
import { TraceService } from "@gms-flex/services-common";
import { Subscription } from "rxjs";
import { SystemBrowserBxSubstituteService } from "src/app/bx-gms-mapper/system-browser/system-browser-bx-substitute.service";

const portfolioModuleName = 'cbmsApplication_Portfolio';

@Injectable({
  providedIn: 'root'
})
export class PortfolioSnapinService {
  constructor(
    private readonly messageBroker: IHfwMessage,
    private readonly systemBrowserService: SystemBrowserBxSubstituteService,
    private readonly traceService: TraceService
  ) { }

  private subscriptions: Subscription[] = [];
  private storedSelectionMessage: MessageParameters | undefined;

  /**
   * Selects a site in the "System Manager" frame and sets the selection message.
   * @param partitionId - The ID of the partition where the site is located.
   * @param siteId - The unique identifier of the site to be selected.
   */
  public selectSiteInSystemManager(partitionId: string, siteId: string): void {
    this.processSiteSelection(partitionId, siteId, 'system-manager', 'System Manager');
  }

  /**
   * Navigates to the "Events" page and sets the selection message.
   * @param partitionId - The ID of the partition where the site is located.
   * @param siteId - The unique identifier of the site to be selected.
   */
  public goToEventsPage(partitionId: string, siteId: string): void {
    this.processSiteSelection(partitionId, siteId, 'event-list', 'Event List');
  }

  /**
 * Unsubscribes from all active subscriptions to prevent memory leaks.
 */
  public unsubscribeSubscriptions(): void {
    this.subscriptions.forEach(sub => sub?.unsubscribe());
    this.subscriptions = [];
  }

  /**
   * Stores the provided selection message for later use.
   * @param selectionMessage - The selection message to be stored.
   */
  public setSelectionMessage(selectionMessage: MessageParameters): void {
    this.storedSelectionMessage = selectionMessage;
  }

  /**
   * Retrieves the stored selection message.
   * @returns The stored selection message, or undefined if no message is stored.
   */
  public getSelectionMessage(): MessageParameters | undefined {
    return this.storedSelectionMessage;
  }

  /**
   * Handles the common logic for selecting a site and switching frames.
   * @param partitionId - The ID of the partition where the site is located.
   * @param siteId - The unique identifier of the site to be selected.
   * @param targetFrame - The target frame to switch to ('system-manager' or 'event-list').
   * @param actionDescription - Description of the action for logging purposes.
   */
  private processSiteSelection(
    partitionId: string,
    siteId: string,
    targetFrame: string,
    actionDescription: string
  ): void {
    this.subscriptions.push(
      this.systemBrowserService.resolveLocation(siteId, partitionId).subscribe(page => {
        if (page?.Nodes?.length > 0) {
          const node = page.Nodes[0];
          const body: GmsMessageData = new GmsMessageData([node]);
          const messageTypes: string[] = [node.Attributes.ManagedTypeName];

          const fullQParamId = new FullQParamId('system-manager', 'SystemQParamService', 'primary');
          const newQParam: QParam = { name: fullQParamId.fullId(), value: node.Designation };
          const selectionMessage: MessageParameters = { types: messageTypes, messageBody: body, qParam: newQParam };

          // Set the selection message for later use
          this.setSelectionMessage(selectionMessage);

          // Switch the frame using the selection message
          this.messageBroker.switchToNextFrame(targetFrame, selectionMessage).subscribe(frameChanged => {
            this.traceService.info(
              portfolioModuleName,
              'Site list component: ',
              `${actionDescription} completed. Result: %s`,
              frameChanged
            );
          });
        } else {
          this.traceService.warn(portfolioModuleName, `No nodes found for partitionId: ${partitionId}, siteId: ${siteId}`);
        }
      }, error => {
        this.traceService.error(portfolioModuleName, `Error resolving location for partitionId: ${partitionId}, siteId: ${siteId}`, error);
      })
    );
  }
}
