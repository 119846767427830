import { CommonModule } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HfwControlsModule } from '@gms-flex/controls';
import { GmsSnapInCommonModule } from '@gms-flex/snapin-common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SiInlineNotificationModule } from '@simpl/element-ng';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { systemBrowserSnapInRouting } from './gms-system-browser-snapin.routing';
import { SystemBrowserSnapInService } from './services/system-browser-snapin.service';
import { SystemBrowserSnapInComponent } from './snapin/system-browser-snapin.component';

export const createTranslateLoader = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http, './@gms-flex/system-browser/i18n/', '.json');

@NgModule({ declarations: [SystemBrowserSnapInComponent],
  exports: [SystemBrowserSnapInComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [AccordionModule, BsDropdownModule, PopoverModule,
    SiInlineNotificationModule, TranslateModule.forChild({
      loader: { provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient] },
      isolate: true
    }), CommonModule, FormsModule, HfwControlsModule, GmsSnapInCommonModule, systemBrowserSnapInRouting], 
  providers: [
    SystemBrowserSnapInService, provideHttpClient(withInterceptorsFromDi())
  ] })

export class GmsSystemBrowserSnapInModule {
  public static forRoot(): ModuleWithProviders<GmsSystemBrowserSnapInModule> {
    return {
      ngModule: GmsSystemBrowserSnapInModule
    };
  }
}
