import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { SiWidgetBaseComponent } from '../si-widget-base.component';
import {
  SiTimelineWidgetItem,
  SiTimelineWidgetItemComponent
} from './si-timeline-widget-item.component';

@Component({
  selector: 'si-timeline-widget-body',
  templateUrl: './si-timeline-widget-body.component.html',
  styleUrl: './si-timeline-widget-body.component.scss',
  standalone: true,
  imports: [SiTimelineWidgetItemComponent]
})
export class SiTimelineWidgetBodyComponent
  extends SiWidgetBaseComponent<SiTimelineWidgetItem[]>
  implements OnChanges
{
  /**
   * Number of skeleton progress indication items.
   *
   * @defaultValue 4
   */
  @Input() numberOfItems = 4;

  /**
   * Whether to show or hide the description row during skeleton progress indication.
   *
   * @defaultValue `true`
   */
  @Input() showDescription = true;

  /** Used to display the defined number of ghost items */
  protected ghosts = new Array(this.numberOfItems);

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.numberOfItems?.currentValue) {
      this.ghosts = new Array(this.numberOfItems);
      this.cdRef.markForCheck();
    }
  }
}
