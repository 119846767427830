<si-card
  class="h-100"
  [heading]="heading"
  [primaryActions]="primaryActions"
  [secondaryActions]="secondaryActions"
  [imgSrc]="imgSrc"
  [imgDir]="imgDir"
  [imgAlt]="imgAlt"
  [imgObjectFit]="imgObjectFit"
  [imgObjectPosition]="imgObjectPosition"
>
  <div class="card-body" body>
    <si-value-widget-body
      [value]="value"
      [unit]="unit"
      [icon]="icon"
      [description]="description"
      [disableAutoLoadingIndicator]="disableAutoLoadingIndicator"
      [showLoadingIndicator]="showLoadingIndicator"
      [initialLoadingIndicatorDebounceTime]="initialLoadingIndicatorDebounceTime"
    />
  </div>
  @if (link) {
    <div class="card-footer" footer>
      <a [siLink]="link">{{ link.title | translate }}</a>
    </div>
  }
</si-card>
