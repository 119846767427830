@if (!showLoadingIndicator && value) {
  <div class="d-flex flex-row">
    <div class="d-flex flex-column align-items-center gap-1 pt-4 pe-4">
      <si-icon
        [icon]="value.icon"
        [color]="value.iconColor"
        [stackedIcon]="value.stackedIcon"
        [stackedColor]="value.stackedIconColor"
        [alt]="value.iconAlt"
      />
      <div class="si-timeline-widget-item-lower-line"></div>
    </div>
    <div class="d-flex flex-column gap-1 py-4">
      <span class="si-caption text-secondary">{{ value.timeStamp | translate }}</span>
      <span class="si-title-2">{{ value.title | translate }}</span>
      @if (value.description) {
        <span class="si-body-2 text-secondary">{{ value.description | translate }}</span>
      }
    </div>
  </div>
} @else {
  <div class="d-flex flex-row">
    <div class="d-flex flex-column align-items-center gap-1 pt-4 pe-4">
      <div class="p-5 si-skeleton"></div>
    </div>
    <div class="d-flex flex-column flex-grow-1 gap-1 py-4">
      <span class="si-link-widget-skeleton"></span>
      <span class="si-link-widget-skeleton" [class.w-50]="!showDescription"></span>
      @if (showDescription) {
        <span class="si-link-widget-skeleton w-50"></span>
      }
    </div>
  </div>
}
