import { CdkPortalOutlet, DomPortal, PortalModule } from '@angular/cdk/portal';
import { ViewportScroller } from '@angular/common';
import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ScrollbarHelper } from '@simpl/element-ng/common';
import {
  BOOTSTRAP_BREAKPOINTS,
  ElementDimensions,
  ResizeObserverService
} from '@simpl/element-ng/resize-observer';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SiDashboardCardComponent } from './si-dashboard-card.component';
import { SiDashboardService } from './si-dashboard.service';

const FIX_SCROLL_PADDING_RESIZE_OBSERVER_THROTTLE = 10;

@Component({
  selector: 'si-dashboard',
  templateUrl: './si-dashboard.component.html',
  styleUrl: './si-dashboard.component.scss',
  host: { class: 'si-layout-fixed-height' },
  providers: [SiDashboardService],
  standalone: true,
  imports: [PortalModule, SiTranslateModule]
})
export class SiDashboardComponent implements OnChanges, OnDestroy, AfterViewInit {
  /**
   * Heading for the dashboard page.
   */
  @Input() heading?: string;

  /**
   * Opt-in to enable expand interaction for all cards.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) enableExpandInteractions = false;

  /**
   * Option to turn off the sticky behavior of the heading and menu bar.
   *
   * @defaultValue true
   */
  @Input({ transform: booleanAttribute }) sticky = true;

  /**
   * Option to hide the menu bar.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) hideMenubar = false;

  /**
   * Is `true` if a card is expanded.
   * @defaultref {@link _isExpanded}
   */
  get isExpanded(): boolean {
    return this._isExpanded;
  }

  protected dashboardFrameEndPadding: number | null = null;

  private _isExpanded = false;
  private unsubscribeFromCards = new Subject<void>();
  private unsubscribe = new Subject<void>();
  private scrollPosition: [number, number] = [0, 0];

  private cards: SiDashboardCardComponent[] = [];
  @ViewChild('expandedPortalOutlet', { read: CdkPortalOutlet, static: true })
  private expandedPortalOutlet!: CdkPortalOutlet;
  @ViewChild('dashboardFrame', { static: true })
  private dashboardFrame!: ElementRef<HTMLElement>;
  @ViewChild('dashboard', { static: true })
  private dashboard!: ElementRef<HTMLElement>;

  private dashboardFrameDimensions?: ElementDimensions;
  private dashboardDimensions?: ElementDimensions;

  private scroller = inject(ViewportScroller);
  private dashboardService = inject(SiDashboardService);
  private resizeObserver = inject(ResizeObserverService);
  private scrollbarHelper = inject(ScrollbarHelper);
  private cdRef = inject(ChangeDetectorRef);

  constructor() {
    this.dashboardService.cards$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(cards => this.subscribeToCards(cards));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.enableExpandInteractions) {
      this.initCards();
    }
  }

  ngAfterViewInit(): void {
    this.resizeObserver
      .observe(this.dashboard.nativeElement, FIX_SCROLL_PADDING_RESIZE_OBSERVER_THROTTLE)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(x => this.setDashboardFrameEndPadding(this.dashboardFrameDimensions, x));
    this.resizeObserver
      .observe(this.dashboardFrame.nativeElement, FIX_SCROLL_PADDING_RESIZE_OBSERVER_THROTTLE)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dims => this.setDashboardFrameEndPadding(dims, this.dashboardDimensions));
  }

  ngOnDestroy(): void {
    this.unsubscribeFromCards.next();
    this.unsubscribeFromCards.complete();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private subscribeToCards(cards: SiDashboardCardComponent[]): void {
    this.cards = cards;
    this.initCards();
  }

  private initCards(): void {
    // unsubscribe from previous cards
    this.unsubscribeFromCards.next();

    for (const card of this.cards) {
      // We only enforce expand if the dashboard value is true, otherwise we would remove the individual
      // card settings.
      if (this.enableExpandInteractions) {
        card.enableExpandInteraction = this.enableExpandInteractions;
      }

      card.expandChange.pipe(takeUntil(this.unsubscribeFromCards)).subscribe((expand: boolean) => {
        if (expand) {
          this.expand(card);
        } else {
          this.restoreDashboard();
        }
        this.cdRef.markForCheck();
      });
    }
  }

  /**
   * Expands the provided card to full size in the dashboard.
   * @param card - The card to be expanded.
   */
  public expand(card: SiDashboardCardComponent): void {
    if (this.isExpanded) {
      this.restoreDashboard();
    }
    if (this.sticky) {
      this.scrollPosition = [
        this.dashboardFrame.nativeElement.scrollLeft,
        this.dashboardFrame.nativeElement.scrollTop
      ];
    } else {
      this.scrollPosition = this.scroller.getScrollPosition();
    }

    // Make sure card.expand() is called first and prevent recursions.
    if (!card.isExpanded) {
      card.expand();
    } else {
      if (!card.showMenubar) {
        this.hideMenubar = true;
      }
      this._isExpanded = true;
      this.expandedPortalOutlet.detach();
      this.expandedPortalOutlet.attach(new DomPortal(card.element.nativeElement));
    }
  }

  /**
   * Restores the expanded card to it's previous position.
   */
  public restore(): void {
    // #restoreDashboard() should only be called once after all
    // possible (wrong or multiple) expanded cards are
    // restored. Therefor we first unsubscribe from all
    // card events.
    this.unsubscribeFromCards.next();

    // Restore all cards
    for (const card of this.cards) {
      if (card.isExpanded) {
        card.restore();
      }
    }
    // Restore the dashboard and scroll to previous position
    this.restoreDashboard();
    // Subscribe to cards events again
    this.initCards();
    this.cdRef.markForCheck();
  }

  /**
   * Restored the UI state of the dashboard. This method is only part
   * of restoring a card and needs to be invoked after the card.restore()
   * method. In general this is achieved by listening to card events.
   */
  private restoreDashboard(): void {
    this.expandedPortalOutlet.detach();
    this.hideMenubar = false;
    this.toggleCardsHide(false);
    const scrollBehavior = document.documentElement.style.scrollBehavior;
    document.documentElement.style.scrollBehavior = 'auto';
    setTimeout(() => {
      if (this.sticky) {
        this.dashboardFrame.nativeElement.scrollTo({
          left: this.scrollPosition[0],
          top: this.scrollPosition[1],
          behavior: 'auto'
        });
      } else {
        this.scroller.scrollToPosition(this.scrollPosition);
        document.documentElement.style.scrollBehavior = scrollBehavior;
      }
      this.cdRef.markForCheck();
    });
    this._isExpanded = false;
  }

  private toggleCardsHide(expand: boolean): void {
    for (const card of this.cards) {
      card.hide = !card.isExpanded && expand;
    }
  }

  private setDashboardFrameEndPadding(
    dashboardFrameDimensions?: ElementDimensions,
    dashboardDimensions?: ElementDimensions
  ): void {
    if (!this.sticky) {
      return;
    }

    if (
      this.dashboardDimensions === dashboardDimensions &&
      this.dashboardFrameDimensions === dashboardFrameDimensions
    ) {
      return;
    }

    this.dashboardDimensions = dashboardDimensions;
    this.dashboardFrameDimensions = dashboardFrameDimensions;

    let padding = document.body.offsetWidth >= BOOTSTRAP_BREAKPOINTS.mdMinimum ? 32 : 16;
    if (
      dashboardDimensions &&
      dashboardFrameDimensions &&
      dashboardDimensions.height > dashboardFrameDimensions.height
    ) {
      padding = padding - this.scrollbarHelper.width;
    }
    this.dashboardFrameEndPadding = padding;
    this.cdRef.markForCheck();
  }
}
