import { Injectable } from '@angular/core';

import { ObjectManagerCoreSettingsServiceBase } from './object-manager-core-settings.service.base';

/**
 * ObjectManagerCoreSettings service.
 * Provides the methods to get if object manager functionalities are visible.
 *
 * @export
 * @class ObjectManagerCoreSettingsService
 * @extends {ObjectManagerCoreSettingsServiceBase}
 */

@Injectable({
  providedIn: 'root'
})
export class ObjectManagerCoreSettingsService implements ObjectManagerCoreSettingsServiceBase {
  public showSystemIdSuffix(): boolean {
    return true;
  }
}