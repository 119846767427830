import { Injectable } from '@angular/core';
import { SystemBrowserSettingsServiceBase } from '@gms-flex/system-browser';

@Injectable()
export class SystemBrowserSettingsBxSubstituteService extends SystemBrowserSettingsServiceBase {
  public constructor() {
    super();
  }

  public showFilterButton(): boolean {
    return false;
  }
}