import { Injectable } from '@angular/core';
import { BehaviorSubject, first, forkJoin, map, Observable, of, switchMap } from 'rxjs';

import { LocationServiceBase } from '../location';
import { SiteSelectionServiceBase } from './site-selection.service.base';
/**
 * Implementation for site selection handling.
 *
 * @export
 * @class SiteSelectionService
 */
@Injectable({
  providedIn: 'root'
})
export class SiteSelectionService extends SiteSelectionServiceBase {

  private readonly _selectedSite: BehaviorSubject<{
    objectId: string | undefined,
    singleSiteActive: boolean | undefined,
    siteName: string | undefined,
    objectType: string | undefined,
    buildingsIds: string[] | undefined
  }>
    = new BehaviorSubject<{
      objectId: string | undefined,
      singleSiteActive: boolean | undefined,
      siteName: string | undefined,
      objectType: string | undefined,
      buildingsIds: string[] | undefined
    }>({
      objectId: undefined,
      singleSiteActive: undefined,
      siteName: undefined,
      objectType: undefined,
      buildingsIds: undefined
    });

  public constructor(
    private readonly locationService: LocationServiceBase) {
    super();
  }

  /**
   * this method should be used to set the current selectedSite and change the current mode: singleSite or multiSite
   * @param objectId ObjectId of the selected site in the form of System:ObjectId where system is the PartitionId
   * @param singleSiteActive this flag indicate if we are working in singleSite or multiSite mode
   */
  public setSite(objectId: string | undefined, singleSiteActive: boolean | undefined): void {
    if (objectId == undefined) {
      this._selectedSite.next({
        objectId: objectId,
        singleSiteActive: singleSiteActive,
        siteName: undefined,
        objectType: undefined,
        buildingsIds: undefined
      });

      return;
    }

    const partitionId = objectId?.split(':')[0];
    const siteId = objectId?.split(':')[1];

    const TypeAndBuildings = this.locationService.isLocationACampus(partitionId, siteId).pipe(switchMap(isACampus => {
      if (isACampus) {
        return this.locationService.getBuildingsIdsOfACampus(partitionId, siteId).pipe(map(buildingsIds => {
          return {
            isACampus: isACampus,
            buildingsIds: buildingsIds
          }
        }));
      } else {
        return of([siteId]).pipe(map(buildingsIds => {
          return {
            isACampus: isACampus,
            buildingsIds: buildingsIds
          }
        }));
      }
    }));

    forkJoin(
      [this.locationService.getSiteName(partitionId, siteId), TypeAndBuildings])
      .pipe(first()).subscribe(([siteName, objectInformations]) => {
        this._selectedSite.next({
          objectId: objectId,
          singleSiteActive: singleSiteActive,
          siteName: siteName,
          objectType: objectInformations.isACampus ? 'campus' : 'building',
          buildingsIds: objectInformations.buildingsIds
        });
      });
  }

  /**
   * the returned value if set, will be an object, the objectId will be in the form of System:ObjectId where system is the PartitionId
   * example: fec4ddd3-fe27-4477-b8ba-e12d3c971abb:4451b372-c5df-480d-be89-04aed687ed97
   */
  public get selectedSite(): Observable<{
    objectId: string | undefined,
    singleSiteActive: boolean | undefined,
    siteName: string | undefined,
    objectType: string | undefined,
    buildingsIds: string[] | undefined
  }> {
    return this._selectedSite.asObservable();
  }
}
