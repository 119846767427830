import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class PersistenceService {

  public get customerId(): string {
    return localStorage.getItem('selectedCustomerId');
  }

  public set customerId(customerId: string) {
    localStorage.setItem('selectedCustomerId', customerId);
  }

  public get partitionIds(): string[] {
    const partitionIds = localStorage.getItem('selectedPartitions');
    const partitionIdsArray = partitionIds?.split(',');
    return partitionIdsArray?.length > 0 ? partitionIdsArray : [];
  }

  public set partitionIds(partitionIds: string[]) {
    localStorage.setItem('selectedPartitions', partitionIds.join(','));
  }

}
