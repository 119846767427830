@if (!showLoadingIndicator && value) {
  @if (value.badge) {
    <span class="mx-0 badge me-4" [ngClass]="badgeColor">{{ value.badge | translate }}</span>
  }
  <span class="me-auto">
    @if (!isLink) {
      <span class="si-title-2">{{ label | translate }}</span>
    } @else {
      <a class="" [siLink]="link"
        >{{ link.title | translate }}<i class="link-icon link-start element-right-2 flip-rtl"></i
      ></a>
    }
    @if (value.description) {
      <div class="mt-1 si-body-2 text-secondary">{{ value.description | translate }}</div>
    }
  </span>
  <span>{{ value.text | translate }}</span>
  @if (value.action) {
    @if (value.action.action) {
      <button
        type="button"
        class="btn btn-circle btn-sm btn-tertiary ms-4 p-2"
        [attr.aria-label]="value.action.title | translate"
        [siLink]="value.action"
      >
        <i [class]="'icon ' + value.actionIcon"></i>
      </button>
    } @else {
      <a
        class="ms-4 p-2"
        [siLink]="value.action"
        [attr.aria-label]="value.action.title | translate"
      >
        <i [class]="'icon ' + value.actionIcon"></i>
      </a>
    }
  }
} @else {
  <div class="si-link-widget-skeleton my-4"></div>
}
