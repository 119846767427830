import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { catchError, EMPTY, expand, filter, from, map, mergeMap, Observable, reduce, tap } from 'rxjs';
import { TraceModules } from 'src/app/core/shared/trace-modules';
import { environment } from 'src/environments/environment';

import { HttpUtilityService } from '../shared/http-utility.service';
import { EventBx, EventCategoryBx, EventResponsePaged, EventSummaryResponse, Summary } from './events-proxy.model';

const eventsUrl = `${environment.bxPlatform.alarmVerticalApiUrl}/v1/organizations`

@Injectable({
  providedIn: 'root'
})
export class EventsProxyService {

  private readonly supportedCategories: Map<EventCategoryBx, EventCategoryBx> = new Map<EventCategoryBx, EventCategoryBx>();
  private readonly eventsSummaryResKeysToSkip: string[] = [];

  public constructor(
    private readonly traceService: TraceService,
    private readonly httpClient: HttpClient,
    private readonly httpUtilityService: HttpUtilityService) {

    this.supportedCategories.set(EventCategoryBx.Emergency, EventCategoryBx.Emergency);
    this.supportedCategories.set(EventCategoryBx.LifeSafety, EventCategoryBx.LifeSafety);
    this.supportedCategories.set(EventCategoryBx.Security, EventCategoryBx.Security);
    this.supportedCategories.set(EventCategoryBx.High, EventCategoryBx.High);
    this.supportedCategories.set(EventCategoryBx.Medium, EventCategoryBx.Medium);
    this.supportedCategories.set(EventCategoryBx.Low, EventCategoryBx.Low);
    this.supportedCategories.set(EventCategoryBx.Fault, EventCategoryBx.Fault);
    this.supportedCategories.set(EventCategoryBx.Trouble, EventCategoryBx.Trouble);
    this.supportedCategories.set(EventCategoryBx.Supervisory, EventCategoryBx.Supervisory);
    this.supportedCategories.set(EventCategoryBx.Status, EventCategoryBx.Status);
    this.supportedCategories.set(EventCategoryBx.None, EventCategoryBx.None);
    
    this.eventsSummaryResKeysToSkip.push('siteId');
    this.eventsSummaryResKeysToSkip.push('noOfOpenAlarms');
    this.eventsSummaryResKeysToSkip.push('updatedAt');

    this.traceService.info(TraceModules.bxServicesEvents, 'EventsProxyService created.');
  }

  public getEventsOfBuilding(partitionId: string, buildingId: string): Observable<EventBx[]> {
    this.traceService.debug(TraceModules.bxServicesEvents,
      `EventsProxyService.getEventsOfBuilding() called: partitionId: ${partitionId}, buildingId: ${buildingId}`);

    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${eventsUrl}/${partitionId}/buildings/${buildingId}/events`;
    let params: HttpParams = new HttpParams();
    params = params.set('minPageSize', 1000);

    return this.httpClient.get<EventResponsePaged>(url, { headers, params, observe: 'response' }).pipe(
      expand(response => {
        if (response.body?.lastEvaluatedKey) {
          params = params.set('lastEvaluatedKey', response.body?.lastEvaluatedKey.trim());
          return this.httpClient.get<EventResponsePaged>(url, { headers, observe: 'response', params });
        } else {
          return EMPTY;
        }
      }),
      map(response => response.body?.events ? response.body.events : []),
      reduce((accumulator, current) => [...accumulator, ...current]),
      tap(events => this.mapCategories(events)),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getEventsOfBuilding()')));
  }

  public getEventsOfBuildingPaged(partitionId: string, buildingId: string, pageSize: number): Observable<EventResponsePaged> {
    this.traceService.debug(TraceModules.bxServicesEvents,
      `EventsProxyService.getEventsOfBuildingPaged() called: partitionId: ${partitionId}, buildingId: ${buildingId}`);
    if (pageSize > 1000) {
      pageSize = 1000; // max allowed size
    }
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${eventsUrl}/${partitionId}/buildings/${buildingId}/events`;
    let params: HttpParams = new HttpParams();
    params = params.set('minPageSize', pageSize);

    return this.httpClient.get(url, { headers, params, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.httpUtilityService.extractData(response, 'getEventsOfBuildingPaged()')),
      tap(eventResponse => this.mapCategories(eventResponse.events)),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getEventsOfBuildingPaged()')));
  }

  /** Ask event summary (and their total counters) for all given partitions */
  public getEventSummary(partitionIds: string[]): Observable<EventSummaryResponse[]> {
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    let params: HttpParams = new HttpParams();
    params = params.set('includeCategory', true);   

    return from(partitionIds).pipe(
      // merge each response into one stream emitted at the end of the last http get
      mergeMap(partitionId => {
        const url = `${eventsUrl}/${partitionId}/event-summary`;

        return this.httpClient.get<EventSummaryResponse[]>(url, { headers, params }).pipe(
          tap(response => this.checkCategories(response)),
          // The Alarm Vertical is returning 204 No Content for a partition with no sites
          // and 200 for a partition that has sites and alarms.
          // The filter handles the case where 204 was returned so that null values do not make it into the mergeMap
          filter(response => Boolean(response))
        );
      }),
      // we use reduce in order to "reduce" the returned observable from a matrix ( response[partition x site] ) 
      // of EventSummaryResponse into an array of EventResponseSummary
      reduce((acc: EventSummaryResponse[], value: EventSummaryResponse[]) => acc.concat(...value), [])
    ); 
  } 
  private checkCategories(res: EventSummaryResponse[]): void {
    if (!res) {
      return;
    }
    res.forEach(r => {
      let unknown = 0;
      const propertyNames: string[] = Object.getOwnPropertyNames(r);
      const descriptors = Object.getOwnPropertyDescriptors(r);
      propertyNames.forEach(p => {
        // check for category keys only
        if (!this.eventsSummaryResKeysToSkip.includes(p as EventCategoryBx)) {
          unknown += this.checkUnknownCategories(descriptors, p);     
        }
      });

      if (unknown > 0) {
        const noneCount: Summary = { count: unknown, updatedAt: '' };
        // Add 'None' property, or modify if it already exists
        Object.defineProperty(r, EventCategoryBx.None, { value: noneCount });
      }
    });
  }

  private checkUnknownCategories(obj: any, key: string): number {
    let unknownCount = 0;
    
    try {
      // Check for unknown categories or if 'None' is already present
      if (!this.supportedCategories.has(key as EventCategoryBx) || (key == EventCategoryBx.None)) {
        if (obj[key] as Summary) {
          const summary = obj[key].value; 
          unknownCount += summary.count;   
        }   
      } 
    } catch (error) {
      this.traceService.error(TraceModules.bxServicesSummary, TraceModules.bxServicesEvents,
        `EventsProxyService.checkCategories() threw error : ${error}`);
    }

    return unknownCount;
  }

  private mapCategories(events: EventBx[]): void {
    events.forEach(event => {
      if (!this.supportedCategories.has(event.category)) {
        event.category = EventCategoryBx.None;
      }
    });
  }
}
