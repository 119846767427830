import { Injectable } from '@angular/core';
import { Partition } from '@building-x/common-ui-ng';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

import { Customer } from '../../bx-services/user/user-self-proxy.model';
import { PersistenceService } from '../../core/services/persistence.service';
// import { Partition } from '../../bx-services/subscription/partition-proxy.model';
interface SelectedData {
  customer: Customer;
  partitions: Partition[]; // TODO!: use Partition from partition-proxy.model
}

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  private readonly selectedDataBehaviorSub = new BehaviorSubject<SelectedData>({ customer: null, partitions: [] });

  constructor(private readonly persistenceService: PersistenceService) {}

  /* eslint-disable-next-line @typescript-eslint/member-ordering */
  public selectedData$ = this.selectedDataBehaviorSub.asObservable().pipe(distinctUntilChanged());
  public setSelectedData(customer: Customer, partitions: Partition[]): void {
    this.persistenceService.customerId = customer.id;
    this.persistenceService.partitionIds = partitions.map(partition => partition.id);
    this.selectedDataBehaviorSub.next({ customer, partitions });
  }

}
