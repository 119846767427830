import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, GuardResult, MaybeAsync, Router, RouterStateSnapshot } from '@angular/router';
import { ServiceWorkerAuth } from '@building-x/common-ui-ng';
import { StateService } from '@gms-flex/core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private readonly state: StateService,
    private readonly router: Router,
    @Inject(ServiceWorkerAuth) private readonly serviceWorker: ServiceWorkerAuth
    
  ) { }

  public canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): MaybeAsync<GuardResult> {
    return new Promise<boolean>(async (resolve, _) => {
      if (await this.serviceWorker.isAuthenticated()) {
        if (this.state.hasRouteConfigBeenReset) {
          resolve(true);
          return;
        }
        // route config not ready so redirect to loading page.
        this.router.navigate(['/loading'], { skipLocationChange: true });
        resolve(false);
        return;
      }

      // Not authenticated
      this.router.navigate(['/login']);
      resolve(false);
    });
  }

}
