import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceWorkerAuth } from '@building-x/common-ui-ng';

import { config } from '../../../assets/app-config';

export interface AccountInfo {
  title?: string;
  image?: string;
}

@Injectable({
  providedIn: 'root'
})

export class SessionService {
  private readonly signSignOnUrl = config.singleSignOnURL;
  private readonly whiteListURLs = ['login', 'logout'];

  constructor(
    private readonly router: Router,
    @Inject(ServiceWorkerAuth) private readonly serviceWorker: ServiceWorkerAuth
  ) { }

  public async initialize(): Promise<void> {
    this.setURLParams();
    this.serviceWorker.isAuthenticated().then(async (res: any) => {
      if (res) {
        if (window.location.href.toString().includes(this.signSignOnUrl)) {
          // handle SSO when user already logged In (older session present)
          await this.handleSSO();
        } else {
          if (window.location.href.toString().includes('?code=') && !sessionStorage.getItem('entryPointUrl')) {
            this.router.navigate(['/']);
          }
          this.navigateToParam();
        }
      } else if (window.location.href.toString().includes(this.signSignOnUrl)) {
        // handle SSO (with new session)
        await this.handleSSO();
      }
    });
  }

  public async getAccountInfo(): Promise<any> {
    const accountData = await this.serviceWorker.getAccountInfo();
    return {
      title: accountData?.value?.name,
      image: accountData?.value?.picture
    };
  }

  private async handleSSO(): Promise<void> {
    await this.serviceWorker.makeAuthRequest();
    this.navigateToParam();
  }

  private setURLParams(): void {
    let param = window.location.hash;
    if (param.length > 1 && !this.whiteListURLs.find(ele => param === `#/${ele}`)) {
      if (param.includes(`#${this.signSignOnUrl}`)) {
        param = param.replace(`#${this.signSignOnUrl}`, '');
      }
      // else if (param.startsWith('#/')) {
      //   param = param.substring(2);
      // }
      if (!sessionStorage.getItem('entryPointUrl')) {
        sessionStorage.setItem('entryPointUrl', param);
      }
    }
  }

  private navigateToParam(): void {
    const param = sessionStorage.getItem('entryPointUrl');
    if (param) {
      sessionStorage.removeItem('entryPointUrl');
      this.router.navigateByUrl(param);
    } else {
      this.router.navigate(['/']);
    }
  }
}

// @Injectable({
//   providedIn: 'root'
// })
// export class MockSessionService extends SessionService {
// }
