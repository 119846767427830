import {
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';

/**
 * The SiWidgetBaseComponent<T> implements the timing for the skeleton loading
 * indicator of widgets. It supports a generic value input property that represents
 * the main value to be displayed by a widget. When the value is not set, the `showLoadingIndicator`
 * changes after the `initialLoadingIndicatorDebounceTime` delay to `true` and subclasses
 * should show the skeleton loading indicator.
 */
@Component({
  template: '',
  standalone: true
})
export abstract class SiWidgetBaseComponent<T> implements OnInit, OnChanges {
  /**
   * The main value to be displayed. If no value is set,
   * the skeleton indicates the loading of the value. Disable
   * the automatic loading mechanism by setting `SiWidgetBodyBaseComponent.disableAutoLoadingIndicator`.
   */
  @Input({ required: true }) value?: T;
  /**
   * Option to disable automatic start of skeleton loading indication.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) disableAutoLoadingIndicator = false;
  /**
   * Input to start and stop the skeleton loading indication.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) showLoadingIndicator = false;
  /**
   * Initial delay time in milliseconds before enabling loading indicator.
   * Only used once initially during construction.
   *
   * @defaultValue 300
   */
  @Input() initialLoadingIndicatorDebounceTime = 300;

  protected cdRef = inject(ChangeDetectorRef);
  protected loadingTimer?: ReturnType<typeof setTimeout>;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !this.disableAutoLoadingIndicator &&
      !changes.value?.firstChange &&
      changes.value?.currentValue
    ) {
      if (this.loadingTimer) {
        clearTimeout(this.loadingTimer);
        this.loadingTimer = undefined;
      }

      this.showLoadingIndicator = false;
      this.cdRef.markForCheck();
    }
  }

  ngOnInit(): void {
    if (!this.disableAutoLoadingIndicator && !this.value) {
      this.loadingTimer = setTimeout(() => {
        this.showLoadingIndicator = !this.value;
        this.cdRef.markForCheck();
      }, this.initialLoadingIndicatorDebounceTime);
    }
  }
}
