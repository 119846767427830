import { Component } from '@angular/core';
import { AVAILABLE_LANGUAGES } from '@building-x/common-ui-ng';

import { environment } from '../../../environments/environment';

@Component({
  templateUrl: './login.component.html'
})

export class LoginComponent {
  protected availableLangs = AVAILABLE_LANGUAGES;
  protected mfaStartDate = new Date(environment.mfaStartDate);
  protected mfaEndDate = new Date(environment.mfaEndDate);
  protected mfaDocLink = environment.mfaDocLink;
  protected bxCommonAssetsPath = environment.bxCommon;
}
