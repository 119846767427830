/**
 * Base class for the oject-manager-core-settings service.
 * Provides the methods to get if object manager functionalities are visible.
 *
 * @export
 * @abstract
 * @class ObjectManagerCoreSettingsServiceBase
 */

export abstract class ObjectManagerCoreSettingsServiceBase {
  public abstract showSystemIdSuffix(): boolean;
}