import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AboutObjectServiceBase } from '../services/about-object.service.base';
import { ObjectItemIfc } from '../view-model/object-item';

@Component({
  selector: 'gms-object-detail-view',
  templateUrl: './object-detail.component.html',
  styleUrl: './container.component.scss'
})
export class ObjectInfoViewComponent implements AfterViewInit, OnDestroy {

  @Input() public objectRef: ObjectItemIfc;
  @Input() public labelTemplate: TemplateRef<any>;
  @Input() public allowBackNavigation: boolean;
  @Input() public allowPathNavigation: boolean;
  @Output() public readonly pathNavigation: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly backNavigation: EventEmitter<void> = new EventEmitter<void>();

  public labelDescription: string;
  public labelName: string;
  public labelAlias: string;
  public labelInformation: string;
  public labelBelongsTo: string;
  public labelShowPath: string;

  @ViewChild('detailContainer', { static: false, read: ElementRef }) private readonly detailContainer: ElementRef;

  constructor(
    aboutObjectService: AboutObjectServiceBase) {

    const ts: TranslateService = aboutObjectService.commonTranslateService;
    ts.get('OM-ABOUT-DESCRIPTION-LABEL').subscribe(s => this.labelDescription = s);
    ts.get('OM-ABOUT-NAME-LABEL').subscribe(s => this.labelName = s);
    ts.get('OM-ABOUT-ALIAS-LABEL').subscribe(s => this.labelAlias = s);
    ts.get('OM-ABOUT-INFORMATION-LABEL').subscribe(s => this.labelInformation = s);
    ts.get('OM-ABOUT-BELONGS-TO-LABEL').subscribe(s => this.labelBelongsTo = s);
    ts.get('OM-ABOUT-SHOW-PATH-LABEL').subscribe(s => this.labelShowPath = s);
  }

  public ngAfterViewInit(): void {
    if (!(this.detailContainer?.nativeElement)) {
      return;
    }
    if (this.objectRef.restoreScrollPosDetail) {
      this.objectRef.restoreScrollPosDetail = false; // reset flag
      this.detailContainer.nativeElement.scrollTop = this.objectRef.lastScrollPosDetail;
    }
  }

  public ngOnDestroy(): void {
    if (!(this.detailContainer?.nativeElement)) {
      return;
    }
    // Save last scroll position of the object-details container in case we
    // want to restore it on re-creation of the component.
    this.objectRef.lastScrollPosDetail = this.detailContainer.nativeElement.scrollTop || 0;
  }

  public onBack(event: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
    this.backNavigation.emit();
  }

  public onPath(event: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
    this.pathNavigation.emit();
  }

  public onCopy(textToCopy: string): void {
    navigator.clipboard.writeText(textToCopy);
  }

}
