import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  Link,
  MenuItem,
  SiCardComponent,
  SiLinkDirective,
  SiTranslateModule,
  TranslatableString
} from '@simpl/element-ng';

import { SiWidgetBaseComponent } from '../si-widget-base.component';
import { SiTimelineWidgetBodyComponent } from './si-timeline-widget-body.component';
import { SiTimelineWidgetItem } from './si-timeline-widget-item.component';

@Component({
  selector: 'si-timeline-widget',
  templateUrl: './si-timeline-widget.component.html',
  standalone: true,
  imports: [SiLinkDirective, SiCardComponent, SiTranslateModule, SiTimelineWidgetBodyComponent]
})
export class SiTimelineWidgetComponent
  extends SiWidgetBaseComponent<SiTimelineWidgetItem[]>
  implements OnChanges
{
  /**
   * Value widget header text.
   */
  @Input() heading?: TranslatableString;
  /**
   * Input list of primary action items. Supports up to **4** actions and omits additional ones.
   *
   * @defaultValue []
   */
  @Input() primaryActions: MenuItem[] = [];
  /**
   * Input list of secondary action items.
   *
   * @defaultValue []
   */
  @Input() secondaryActions: MenuItem[] = [];
  /**
   * Link that leads the user to more information
   * or triggers and action. The `link.title` is displayed.
   * The title will be translated.
   */
  @Input() link?: Link;

  /**
   * Number of skeleton progress indication items.
   *
   * @defaultValue 4
   */
  @Input() numberOfItems = 4;

  /**
   * Whether to show or hide the description row during skeleton progress indication.
   *
   * @defaultValue `true`
   */
  @Input() showDescription = true;

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }
}
