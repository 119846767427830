import { HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { Observable, throwError } from 'rxjs';

export const traceChannelHorizon = 'Horizon';
export const useCache = true;
export const useMulticast = true;

@Injectable({
  providedIn: 'root'
})
export class HttpUtilityService {

  public constructor(
    private readonly traceService: TraceService) {
  }

  public httpGetDefaultHeader(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/vnd.api+json ');
    return headers;
  }

  public extractData(res: HttpResponse<any>, method: string): any {
    if (res.status < 200 || res.status >= 300) {
      throw new Error('Bad response status: ' + res.status);
    } else {
      try {
        const body: any = res.body;
        if (this.traceService.isDebugEnabled(traceChannelHorizon)) {
          if (Array.isArray(body) === true) {
            let traceStr = '';
            (body as any[]).forEach(item => {
              traceStr = traceStr + '\n\n' + JSON.stringify(item);
            });
            this.traceService.debug(traceChannelHorizon, method, 'returned:', traceStr);
          } else {
            this.traceService.debug(traceChannelHorizon, method, 'returned:\n', JSON.stringify(body));
          }
        }
        return body;
      } catch (exc) {
        this.traceService.warn(traceChannelHorizon, method,
          'Response not handled properly; exception caught: ', res.url, '; url=', (exc as Error).message.toString());
      }
    }
  }

  public handleError(error: HttpResponse<any> | any, method: string): Observable<any> {
    try {
      if (error instanceof HttpErrorResponse) {
        this.traceService.error(traceChannelHorizon, method, error.message);
        return throwError(() => new Error(error.message));
      } else {
        const errMsg: string = error.message ? error.message : error.toString();
        this.traceService.error(traceChannelHorizon, method, errMsg);
        return throwError(() => new Error(errMsg));
      }
    } catch (exc) {
      const err: Error = new Error('WSI reply error! See trace.');
      this.traceService.error(traceChannelHorizon, method, err.message);
      return throwError(() => new Error(err.message));
    }
  }

}
