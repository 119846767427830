<si-card
  class="h-100"
  [heading]="heading"
  [primaryActions]="sort ? sortAction : []"
  [actionBarViewType]="'expanded'"
>
  <si-list-widget-body
    body
    [compareFn]="compareFn"
    [filterFn]="filterFn"
    [numberOfLinks]="numberOfLinks"
    [searchPlaceholderLabel]="searchPlaceholderLabel"
    [search]="search"
    [sort]="sort"
    [value]="value"
    [showLoadingIndicator]="showLoadingIndicator"
  >
    <ng-content select="[empty-state]" empty-state />
  </si-list-widget-body>

  @if (link) {
    <div class="card-footer" footer>
      @if (!link.action) {
        <a [siLink]="link">
          {{ link.title }}
          <i class="link-icon link-end element-right-2 flip-rtl"></i>
        </a>
      } @else {
        <button type="button" class="btn btn-link" [siLink]="link">{{ link.title }} </button>
      }
    </div>
  }
</si-card>
