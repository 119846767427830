/**
 * Base class for the system browser-settings service.
 * Provides the methods to get if system browser functionalities are visible.
 *
 * @export
 * @abstract
 * @class SystemBrowserSettingsServiceBase
 */

export abstract class SystemBrowserSettingsServiceBase {
  public abstract showFilterButton(): boolean;
}