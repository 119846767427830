import { Injectable } from '@angular/core';
import { FullSnapInId } from '@gms-flex/core';
import { TraceService } from '@gms-flex/services-common';

import { TraceModules } from '../shared/trace-modules';
import { SystemBrowserViewModel } from '../view-model/snapin-vm';
import { SystemBrowserViewModelBase } from '../view-model/snapin-vm.base';

@Injectable()
export class SystemBrowserSnapInService {

  private readonly _trModule: string = TraceModules.sysBrowser;
  private readonly vmMap: Map<string, SystemBrowserViewModel>;

  /**
   * Generate a unique key based on SNI id.
   */
  private static getVmKeyValue(sniId: FullSnapInId): string {
    return sniId.fullId();
  }

  /**
   * Constructor.
   */
  constructor(private readonly traceService: TraceService) {
    this.vmMap = new Map<string, SystemBrowserViewModel>();
  }

  /**
   * Get existing or create new snap-in view-model.
   */
  public registerViewModel(sniId: FullSnapInId): SystemBrowserViewModelBase {
    if (!sniId) {
      throw new Error('sniId argument undefined');
    }
    const key: string = SystemBrowserSnapInService.getVmKeyValue(sniId);

    let vm: SystemBrowserViewModel = this.vmMap.get(key);
    if (!vm) {
      this.traceService.info(this._trModule, `Create new view-model: sniKey=[${key}]`);
      vm = new SystemBrowserViewModel(sniId, this.traceService);
      this.vmMap.set(key, vm);
    }
    // vm.activate();       Let the SNI do this!
    return vm;
  }

  /**
   * Remove and dispose of snap-in view-model.
   */
  public unregisterViewModel(sniId: FullSnapInId): void {
    if (!sniId) {
      return;
    }
    const key: string = SystemBrowserSnapInService.getVmKeyValue(sniId);

    const vm: SystemBrowserViewModel = this.vmMap.get(key);
    if (vm) {
      this.traceService.info(this._trModule, `Destroy view-model: sniKey=[${key}]`);
      vm.dispose();
      this.vmMap.delete(key);
    }
  }
}
