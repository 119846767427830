import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SiIconComponent, SiTranslateModule, TranslatableString } from '@simpl/element-ng';

import { SiWidgetBaseComponent } from '../si-widget-base.component';

export interface SiTimelineWidgetItem {
  /**
   * The timestamp of the item.
   */
  timeStamp: TranslatableString;
  /**
   * The title of the item.
   */
  title: TranslatableString;
  /**
   * Optional translatable description.
   */
  description?: TranslatableString;
  /**
   * The icon of the item.
   */
  icon: string;
  /**
   * The color of the icon.
   */
  iconColor?: string;
  /**
   * The stacked icon of the item.
   */
  stackedIcon?: string;
  /**
   * The color of the stacked icon.
   */
  stackedIconColor?: string;
  /**
   * The alt text of the icon
   */
  iconAlt?: TranslatableString;
}

/**
 * The items of the `<si-timeline-widget>`.
 */
@Component({
  selector: 'si-timeline-widget-item',
  templateUrl: './si-timeline-widget-item.component.html',
  host: {
    role: 'listitem'
  },
  standalone: true,
  imports: [SiIconComponent, SiTranslateModule]
})
export class SiTimelineWidgetItemComponent
  extends SiWidgetBaseComponent<SiTimelineWidgetItem>
  implements OnInit, OnChanges
{
  /**
   * Whether to show or hide the description row during skeleton progress indication.
   *
   * @defaultValue `true`
   */
  @Input() showDescription = true;
}
