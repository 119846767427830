import { NgIf } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { SiCardComponent } from '@simpl/element-ng/card';
import { MenuItem } from '@simpl/element-ng/common';
import {
  ContentActionBarMainItem,
  SiContentActionBarComponent,
  ViewType
} from '@simpl/element-ng/content-action-bar';
import { SiTranslateModule, TranslatableString } from '@simpl/element-translate-ng/translate';

import { SiDashboardService } from './si-dashboard.service';

@Component({
  selector: 'si-dashboard-card',
  templateUrl: '../card/si-card.component.html',
  styleUrls: ['../card/si-card.component.scss', './si-dashboard-card.component.scss'],
  standalone: true,
  imports: [NgIf, SiContentActionBarComponent, SiTranslateModule]
})
export class SiDashboardCardComponent
  extends SiCardComponent
  implements OnInit, OnChanges, OnDestroy
{
  @HostBinding('class.elevation-1') protected override get classElevation(): boolean {
    return !this.isExpanded;
  }
  @HostBinding('class.elevation-2') protected get classElevationExpanded(): boolean {
    return this.isExpanded;
  }
  @HostBinding('class.expanded') protected get classExpanded(): boolean {
    return this.isExpanded;
  }
  @HostBinding('class.d-none') protected get hideCard(): boolean {
    return this.hide;
  }

  /**
   * Description of cancel button & action.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DASHBOARD.RESTORE:Restore`
   * ```
   */
  @Input() restoreText = $localize`:@@SI_DASHBOARD.RESTORE:Restore`;
  /**
   * Description of expand button & action.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DASHBOARD.EXPAND:Expand`
   * ```
   */
  @Input() expandText = $localize`:@@SI_DASHBOARD.EXPAND:Expand`;
  /**
   * Option to enable and show the UI controls for card expand functionality.
   * `Expand` and `restore` action items will be added to the content action bar.
   * The expand resizing has to be implemented by the container of the card.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) enableExpandInteraction = false;
  /**
   * Used in combination with si-dashboard to show filters when a card is expanded or not.
   *
   * @defaultValue true
   */
  @Input({ transform: booleanAttribute }) showMenubar = true;
  /**
   * Emitter for size state change
   */
  @Output() readonly expandChange = new EventEmitter<boolean>();

  private get expandRestoreIconTooltip(): TranslatableString {
    return this.isExpanded ? this.restoreText : this.expandText;
  }

  private _isExpanded = false;
  /**
   * Whether the card is currently expanded.
   */
  get isExpanded(): boolean {
    return this._isExpanded;
  }
  protected set isExpanded(value) {
    this._isExpanded = value;
  }
  /** @internal */
  hide = false;
  private primaryActionsCache: (MenuItem | ContentActionBarMainItem)[] = [];
  private actionBarViewTypeCache: ViewType = this.actionBarViewType;
  private actionBarTitleCache = this.actionBarTitle;
  private expandActionItem!: ContentActionBarMainItem;
  private restoreActionItem!: ContentActionBarMainItem;
  private expandRestoreButtonActions!: ContentActionBarMainItem[];

  /** @internal */
  element = inject(ElementRef);
  private dashboardService = inject(SiDashboardService, { optional: true });
  private cdRef = inject(ChangeDetectorRef);

  constructor() {
    super();
    this.dashboardService?.register(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.primaryActions) {
      this.primaryActionsCache = changes.primaryActions.currentValue
        ? [...changes.primaryActions.currentValue]
        : [];
    }
    if (changes.actionBarViewType?.currentValue) {
      this.actionBarViewTypeCache = changes.actionBarViewType.currentValue;
    }
    if (changes.actionBarTitle?.currentValue) {
      this.actionBarTitleCache = changes.actionBarTitle.currentValue;
    }
    if (
      changes &&
      (changes.enableExpandInteraction || changes.primaryActions || changes.secondaryActions)
    ) {
      this.setupResizeAction();
      this.cdRef.markForCheck();
    }
  }

  ngOnInit(): void {
    this.expandActionItem = {
      type: 'action',
      label: this.expandText,
      icon: 'element-zoom',
      iconOnly: true,
      action: () => this.expandCard(true)
    };
    this.restoreActionItem = {
      type: 'action',
      label: this.restoreText,
      icon: 'element-pinch',
      iconOnly: true,
      action: () => this.expandCard(false)
    };
    this.setupResizeAction();
  }

  ngOnDestroy(): void {
    this.dashboardService?.unregister(this);
  }

  /**
   * Expand the dashboard card.
   */
  expand(): void {
    this.expandCard(true);
  }

  /**
   * Restore the dashboard card to the original, non-expanded state.
   */
  restore(): void {
    this.expandCard(false);
  }

  private setupResizeAction(): void {
    this.expandRestoreButtonActions = [
      {
        type: 'action',
        label: this.isExpanded ? this.restoreText : this.expandText,
        icon: this.isExpanded ? 'element-pinch' : 'element-zoom',
        iconOnly: true,
        action: () => this.expandCard(!this.isExpanded)
      }
    ];

    if (!this.hasContentBarActions()) {
      if (this.enableExpandInteraction) {
        this.primaryActions = this.expandRestoreButtonActions;
        this.actionBarViewType = 'expanded';
        this.actionBarTitle = this.expandRestoreIconTooltip;
      } else {
        this.primaryActions = [];
        this.actionBarViewType = this.actionBarViewTypeCache;
        this.actionBarTitle = this.actionBarTitleCache;
      }
    } else {
      this.actionBarTitle = this.actionBarTitleCache;
      if (!this.enableExpandInteraction) {
        this.primaryActions = this.primaryActionsCache;
        this.actionBarViewType = this.actionBarViewTypeCache;
      } else {
        const action = this.isExpanded ? this.restoreActionItem : this.expandActionItem;
        if (this.primaryActionsCache && this.primaryActionsCache.length > 0) {
          this.primaryActions = [...this.primaryActionsCache, action];
        } else {
          this.primaryActions = [action];
        }
      }
    }
  }

  private expandCard(expand: boolean): void {
    this.isExpanded = expand;
    this.setupResizeAction();
    this.expandChange.next(expand);
  }

  private hasContentBarActions(): boolean {
    return this.primaryActionsCache?.length > 0 || this.secondaryActions?.length > 0;
  }
}
