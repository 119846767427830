import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SystemBrowserSnapInComponent } from './snapin/system-browser-snapin.component';

const systemBrowserSnapInRoutes: Routes = [
  {
    path: '',
    component: SystemBrowserSnapInComponent
  }
];

export const systemBrowserSnapInRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(systemBrowserSnapInRoutes);
