import { Injectable } from '@angular/core';
import {
  Partition, // TODO REMOVE
  Permission,
  SiAODSService,
  SubscriptionsService
} from '@building-x/common-ui-ng';
import { TraceService } from '@gms-flex/services-common';
import { BehaviorSubject, catchError, EMPTY, forkJoin, map, Observable, of, switchMap } from 'rxjs';

import { ContextService } from '../bx-gms-mapper/state/context.service';
import { Customer } from '../bx-services/user/user-self-proxy.model';
import { UserSelfService } from '../bx-services/user/user-self.service';
// import { Partition } from '../bx-services/subscription/partition-proxy.model';
import { PersistenceService } from '../core/services/persistence.service';
import { TraceModules } from '../core/shared/trace-modules';

export const cbmsAppPermission = 'app.boic';

interface CustomerData {
  customerPartitions: Partition[];
  permission: Permission
}

@Injectable({
  providedIn: 'root'
})

export class CustomerSelectorStateService {

  public allCustomers: Customer[] = [];
  public selectedCustomer: Customer;
  public customerPartitions: Partition[] = [];
  public selectedCustomerPartitions: Partition[] = [];
  public allPermissions: Permission;
  public isAllPartitionsLoaded = true; // enable/disable loader on partition page

  public unconfirmedCustomer: Customer;
 
  private readonly allPermissionsSubject = new BehaviorSubject<any>(null);

  constructor(
    private readonly userSelfService: UserSelfService,
    private readonly subscriptionsService: SubscriptionsService,
    private readonly aodsService: SiAODSService,
    private readonly contextService: ContextService,
    private readonly traceService: TraceService,
    private readonly persistenceService: PersistenceService) {
  }

  public initAsync(): Observable<boolean> {
    
    return this.userSelfService.getCustomers().pipe(
      switchMap(customers => {
        if (customers.length === 0) {
          this.traceService.error(TraceModules.bxServicesCustomers, 'No customers found for the current user.');
          return of(false);
        }
        this.allCustomers = customers;

        const savedCustomerId = this.persistenceService.customerId;
        
        // Select the last stored customer if found, or the first available customer
        this.selectedCustomer = this.allCustomers.find(customer => customer.id === savedCustomerId) ?? this.allCustomers[0];

        return this.getSelectedCustomerData(this.selectedCustomer.id).pipe(
          map(customerData => {
            this.setCustomerData(customerData);

            // Get the saved selected partitions and match them with the currently valid partitions;
            // if there is no saved selected partition or there is no match between saved and valid partitions, 
            // the first available partition is selected as default
            const savedSelectedPartitionsIdsArray = this.persistenceService.partitionIds;
            
            // Match saved partition ids with currently available partition ids, to ensure saved partitions are still valid
            const matchingPartitions = this.customerPartitions.filter(partition =>
              savedSelectedPartitionsIdsArray.includes(partition.id)
            );

            this.selectedCustomerPartitions = matchingPartitions.length > 0 ? matchingPartitions : [this.customerPartitions[0]];
            
            this.contextService.setSelectedData(this.selectedCustomer, this.selectedCustomerPartitions);
            this.traceService.info(TraceModules.bxServicesCustomers, `SelectedCustomer: '${this.selectedCustomer}'.
              SelectedCustomerPartitions: '${this.selectedCustomerPartitions}'.`);
            return true;
          })
        );
      })
    );
  }

  public onCustomerChange(event): void {
    this.unconfirmedCustomer = event.id;
    this.reloadData(event.id);
  }

  public reloadData(customerId: string): void {
    this.getSelectedCustomerData(customerId).pipe(
      catchError(() => {
        this.customerPartitions = [];
        this.allPermissions = null;
        this.isAllPartitionsLoaded = true;
        return EMPTY;
      })
    ).subscribe(customerData => { this.setCustomerData(customerData); });

  }

  public onSelectedCustomerPartitions(event): void {
    this.selectedCustomer = event.customer;
    this.selectedCustomerPartitions = event.partitions as Partition[];
           
    this.contextService.setSelectedData(this.selectedCustomer, this.selectedCustomerPartitions);
    this.traceService.info(TraceModules.bxServicesCustomers, `SelectedCustomer: '${this.selectedCustomer}'.
      SelectedCustomerPartitions: '${this.selectedCustomerPartitions}'.`);
  }

  /* Returns all customer permissions  */
  public getAllPermissions(): Observable<any> {
    return this.allPermissionsSubject.asObservable();
  }

  private setCustomerData(customerData: CustomerData): void {
    this.customerPartitions = customerData.customerPartitions;

    this.allPermissions = customerData.permission;
    this.allPermissionsSubject.next(this.allPermissions);

    this.isAllPartitionsLoaded = true;
  }

  private getSelectedCustomerPartitions(customerId: string): Observable<Partition[]> {
    return this.subscriptionsService.getPartitions(customerId, 100, undefined, true).pipe(
      catchError(() => {
        this.traceService.error(TraceModules.bxServicesCustomers, `Error retrieving partitions for customer '${customerId}'.`);
        return [];
      }),
      map(partitionsDetailsData => partitionsDetailsData.data)
    );
  }

  private getSelectedCustomerPermission(customerId: string): Observable<Permission> {
    return this.aodsService.getPermissionsByCustomerId(customerId).pipe(
      catchError(() => {
        this.traceService.error(TraceModules.bxServicesCustomers, `Error retrieving permissions for customer '${customerId}'.`);
        return [];
      }),
      map(permissions => permissions[0] ?? null)
    );
  }

  private getSelectedCustomerData(customerId: string): Observable<CustomerData> {

    const partitions$ = this.getSelectedCustomerPartitions(customerId);
    const permission$ = this.getSelectedCustomerPermission(customerId);

    return forkJoin([partitions$, permission$]).pipe(
      map(([partitions, permission]) => {
        if (partitions.length === 0 || !permission) {
          return { customerPartitions: [], permission: null };
        }

        // Get only the partitions with permission for cbmsApp
        const partitionsWithAppPermission = permission.partitions
          .filter(partition => partition.permissions.includes(cbmsAppPermission))
          .map(partition => partition.targetId);

        // partitionsWithAppPermission can contain hidden partitions, which must not be retrieved
        // Match valid partitions and partitions with permission for cbmsApp
        const customerPartitions = partitions.filter(partition => 
          partitionsWithAppPermission.includes(partition.id)
        );

        return { customerPartitions, permission };
      })
    );
  }
}
