<gms-navbar-primary #navbar [hfwFrames]="frames" [primaryItems]="primaryItems" [isMainManager]="true"
  [notificationSupported]="notificationSupported" [partitionSelectionSupported]="partitionSelectionSupported"
  [appSwitcherSupported]="appSwitcherSupported" [appSwitcherData]="appSwitcherData"
  (utilityPanelChanged)="onUtilityPanelChanged($event)" />

<si-navbar-vertical [items]="currentVerticalItems" [visible]="isVerticalVisible">
  <si-side-panel [collapsible]="isRPCollapsible" [enableMobile]="true" [mode]="'scroll'"
    (contentResize)="contentResize()">
    <div *ngIf="isSiteTitleVisible" class="si-layout-header pt-8 ps-9 mb-4">
      <h2 class="si-layout-title" [class.si-layout-top-element]="true">{{ selectedSiteName }}</h2>
    </div>
    <router-outlet />

    <si-side-panel-content *ngIf="currentFrameId === 'system-manager'" id="system-right-panel-content"
      [heading]="systemRP?.selectedNodeHeading !== undefined ? systemRP.selectedNodeHeading : ''"
      [primaryActions]="systemRP?.primaryActions !== undefined ? systemRP.primaryActions : []"
      [secondaryActions]="systemRP?.secondaryActions !== undefined ? systemRP.secondaryActions : []"
      [statusActions]="systemRP?.statusActions !== undefined ? systemRP.statusActions : []" [searchable]="false"
      [searchPlaceholder]="systemRP?.placeholderFilter !== undefined ? systemRP.placeholderFilter : ''"
      (searchEvent)="systemRP.onFilterChange($event)">
      <gms-sytem-rp #systemRP class="display-fit" [frameId]="'system-manager'" [parentId]="'system-right-panel-content'"
        [isRightPanelExpanded]="rightPanelExpandFlag" />
    </si-side-panel-content>

    <si-side-panel-content *ngIf="currentFrameId === 'event-list'" id="event-right-panel-content"
      [heading]="systemRP?.selectedNodeHeading" [primaryActions]="systemRP?.primaryActions"
      [secondaryActions]="systemRP?.secondaryActions" [statusActions]="systemRP?.statusActions" [searchable]="false"
      [searchPlaceholder]="systemRP?.placeholderFilter" (searchEvent)="systemRP.onFilterChange($event)">
      <gms-sytem-rp #systemRP class="display-fit" [frameId]="'event-list'" [parentId]="'event-right-panel-content'"
        [displayRelatedItems]="false" />
    </si-side-panel-content>

    <hfw-mobile-view hfwMobileOnly />
  </si-side-panel>
</si-navbar-vertical>

<ng-template #custSelector cdkPortal>
  <si-side-panel-content [heading]="'Customer selection'">
    <gms-customer-selector />
  </si-side-panel-content>
</ng-template>

<!--
  NOTE:
  This template is included as a workaround for importing the graphics-common component into events.
  It used to be the case that circular dependencies were allowed in Angular's full compilation mode.

  Due to Angular's partial compilation mode, that type of import is no longer possible.
  If this compilation issue becomes resolved due to changes in Angular's partial compilation mode,
  then this template will be removed.
-->
<ng-template #graphicsCommon let-browserObject="browserObject" let-resolveObs="resolveObs"
  let-resolveExecutionResult="resolveExecutionResult" let-assistedTreatmentStep="assistedTreatmentStep">
  <gms-graphics-common style="height: 500px;" [browserObject]="browserObject" [resolveObs]="resolveObs"
    (resolveExecutionResult)="onResolveGraphicStep(resolveExecutionResult, assistedTreatmentStep)" />
</ng-template>
