import { Injectable } from "@angular/core";
import { ObjectManagerCoreSettingsServiceBase } from "@gms-flex/snapin-common";

@Injectable()
export class ObjectManagerCoreSettingsBxSubstituteService extends ObjectManagerCoreSettingsServiceBase {
  public constructor() {
    super();
  }

  public showSystemIdSuffix(): boolean {
    return false;
  }
}