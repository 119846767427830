import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SiHeaderActionIconItemBase } from './si-header-action-item-icon-base.directive';

/** Adds an action item to the header. Should be located inside `.header-actions`. */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[si-header-action-item], a[si-header-action-item]',
  standalone: true,
  imports: [NgClass],
  templateUrl: './si-header-action-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'header-item focus-inside',
    '[class.dropdown-toggle]': '!!dropdownTrigger'
  }
})
export class SiHeaderActionItemComponent extends SiHeaderActionIconItemBase {
  /** The icon to be shown. */
  @Input({ required: true }) icon!: string;
}
