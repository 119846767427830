import { FullSnapInId } from '@gms-flex/core';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { Observable, Observer } from 'rxjs';

import { TraceModules } from '../shared/trace-modules';
import { SystemBrowserViewModelBase } from './snapin-vm.base';

export class SystemBrowserViewModel implements SystemBrowserViewModelBase {

  private readonly trmod: string = TraceModules.sysBrowser;
  private isActivatedFlag: boolean;
  private isDisposedFlag: boolean;

  public get id(): FullSnapInId {
    return this.sniId;
  }

  public get isActivated(): boolean {
    return Boolean(this.isActivatedFlag);
  }

  constructor(
    private readonly sniId: FullSnapInId,
    private readonly traceService: TraceService) {

    if (isNullOrUndefined(sniId)) {
      throw new Error('sniId cannot be undefined or empty');
    }
    this.isDisposedFlag = false;
    this.isActivatedFlag = false;
  }

  public dispose(): void {
    if (this.isDisposedFlag) {
      return; // already disposed
    }
    this.deactivate();
    this.isDisposedFlag = true;
    if (this.traceService) {
      this.traceService.info(this.trmod, 'Disposed SNI view-model: sniId=%s', this.sniId);
    }
  }

  public deactivate(): void {
    if (this.traceService) {
      this.traceService.info(this.trmod, 'Deactivate SNI view-model: sniId=%s', this.sniId);
    }
    this.isActivatedFlag = false;
  }

  public activate(): Observable<void> {
    this.checkDisposed();
    return new Observable((obs: Observer<void>) => this.activateInternal(obs));
  }

  private activateInternal(obs: Observer<void>): void {
    if (this.traceService) {
      this.traceService.info(this.trmod, 'Activate SNI view-model: sniId=%s', this.id.fullId());
    }
    this.traceService.info(this.trmod, 'Activate SNI view-model complete: sniId=%s', this.id.fullId());
    this.isActivatedFlag = true;
    obs.next(undefined);
    obs.complete();
  }

  private checkDisposed(): void {
    if (this.isDisposedFlag) {
      throw new Error(`View model has been disposed: sniId=${this.sniId}`);
    }
  }

}
